const EnglishContent = {
    companyName: "vermadev",
    companySlogan: "nocode data management solution",
    submitLabel: "Submit",
    startHereLabel: "Start Here",
    loginHereLabel: "Login Here",
    logoutLabel: "Logout",
    generalLabel: "General",
    backLabel: "Back",
    profileLabel: "Profile",
    settingsLabel: "Settings",
    integrationsLabel: "Integrations",
    dashboardLabel: "Dashboard",
    dashboardWelcomeLabel: "Welcome!",
    reportsLabel: "Reports",
    reportLabel: "Report",
    chartTypeLabel: "Chart Type",
    listsLabel: "Lists",
    contentLabel: "Content",
    relationsLabel: "Relations",
    financialsLabel: "Financials",
    continueLabel: "Continue",
    userNameLabel: "Username",
    passwordLabel: "Password",
    nameLabel: "Name",
    industryLabel: "Industry",
    websiteLabel: "Website",
    linkedinLabel: "Linkedin Url",
    facebookLabel: "Facebook Url",
    twitterLabel: "Twitter Url",
    angelLabel: "Angel Url",
    crunchbaseLabel: "Crunchbase Url",
    primaryThemeLabel: "Primary Theme",
    openSettingsLabel: "Open settings",
    addModelLabel: "Add Model",
    modelLabel: "Model",
    addPoolLabel: "Add Pool",
    dashboardNotificationsLabel: "Organization Notifications",
    notificationsLabel: "Notifications",
    emailNotificationsLabel: "Email Notifications",
    emailNotificationsMessage: "Email notifications are customizable, allowing users to choose the types of notifications they want to receive and set their preferences",
    dailyReportsLabel: "Daily Reports",
    dailyReportsMessage: "Get a detailed report of organization activity end of your day. We use various algorithms and machine learning techiques to bring you the most important details of your team's acticity",
    dealsClosingAlert: "Deals closing alerts",
    dealsClosingMessage: "Be the first to get notified when Deals get executed or closed",
    contentDeletionAlert: "Content deletion alerts",
    contentDeletionMessage: "Keeping your data precise is important, get notified whenever assets or content is removed from your organization",
    deviceNotificationsLabel: "Device notifications",
    securityUpdatesMessage: "Get notified only when their are security concerns related with your organization",
    allUpdatesMessage: "Get all important updates and stay in touch with every live update about your organization",
    newPasswordLabel: "New Password",
    bottomFooterLabel: "SSL Secure Registration",
    codeLabel: "Code",
    platformLanguageLabel: "Platform Language",
    platformCurrencyLabel: "Platform Currency",
    organizationNameLabel: "Organization Name",
    confirmPasswordLabel: "Confirm Password",
    confirmationCodeLabel: "Confirmation Code",
    resetPassLabel: "Reset Password",
    firstNameLabel: "First Name",
    lastNameLabel: "Last Name",
    jobTitleLabel: "Job Title",
    emailLabel: "Email",
    defaultPassword: "Default Password",
    dobLabel: "Date Of Birth",
    phoneNumberLabel: "Phone Number",
    contentCollageLabel: "Content Management",
    relationsCollageLabel: "Relationship Management",
    financialsCollageLabel: "Financials Management",
    forgotPassDescription: "Forgot your password?",
    codePassDescription: "Already have a code?",
    resetPassDescription: "Recover it here",
    resetCodeDescription: "Finish Sign up here",
    backToLoginDescription: "Back to login",
    resendCodeLabel: "Resend confirmation code",
    resendCodeDescription: "Thank you for registering with vermadev, type in your email to resend a confirmation code",
    verifyCodeDescription: "Send Verification Email",
    confirmCodeDescription: "I have my code",
    signupSuccessDescription: "Thank you for registering with vermadev. We have sent over a confirmation code to your email, please copy and paste the code here to continue. Make sure to check your spam folder",
    noDataDescription: "No data created here",
    addSectionLabel: "Add Section",
    invoicePreviewLabel: "Invoice Preview",
    inventoriesLabel: "Inventories",
    exploreLabel: "Explore",
    exploreMessage: "Utilizing this feature, you will tapped into the desired organization. Would you wish to still continue?",
    invoiceAmountLabel: "Invoice Total Amount",
    reachOutMessageLabel: "For any question, please reach out to",
    payToLabel: "Pay To",
    viewLabel: "View",
    saveLabel: "Save",
    saveChangesLabel: "Save changes",
    yesLabel: "Yes",
    editLabel: "Edit",
    closeLabel: "Close",
    cancelLabel: "Cancel",
    okayLabel: "Okay",
    errorLabel: "Error",
    downloadCSVLabel: "Download CSV",
    downloadPDFLabel: "Download PDF",
    newTeamMember: "New Team member",
    teamMember: "Team member",
    subscriptionLabel: "Subscription",
    cancelSubscriptionLabel: "Cancel Subscription",
    areYouSureLabel: "Are you sure!",
    cancelSubscriptionTitle: "Are you sure you would like to proceed with cancelling your subscription?",
    cancelSubscriptionDescription: "Proceeding with this action will terminte all team member's account for this organization. We advise, if you havent already, please pause your subscription instead so you may still have access to all your data",
    cancellationQuestion: "Please select a reason for your cancellation",
    thankYouMessage: "Thank you for choosing vermadev for your data management needs, privacy and protection of your data is of highest prioirty to us",
    pauseSubscription: "Pause Subscription",
    currentSubscriptionStatus: "Current Subscription Status",
    pauseSubscriptionTitle: "Are you sure you would like to proceed with pausing your subscription?",
    pauseSubscriptionDescription: "If you proceed, we will keep your data utmost safe and secure. You may easily export any data sets. After this action is performed, all team memebers in your organization will be moved to a Viewer role, allowing easy read only access across the platform. You may choose to resume your subscription here at any pont and all your data settings will be preserved",
    upgradeLabel: "Upgrade",
    upgradeSubscription: "Upgrade Subscription",
    upgradeSubscriptionTitle: "At Verma Consulting, we proudly offer a complete dynamic development experience that works closely with your business operations",
    upgradeSubscriptionDescription: "Data security ensures that sensitive information, such as personal identifiable information (PII), financial data, intellectual property, and trade secrets, remains confidential and is only accessible to authorized individuals or entities. Unauthorized access to such information could lead to identity theft, financial loss, or other forms of fraud",
    currentStatusLabel: "Current Status",
    periodEndsLabel: "Period Ends",
    nextBillingLabel: "Next Billing Date",
    choosePlanLabel: "Choose Plan",
    selectProductLabel: "select product",
    selectModelLabel: "select model",
    selectRoleLabel: "select role",
    selectFrequencyLabel: "select frequency",
    selectChartTypeLabel: "select chart type",
    roleLabel: "Role",
    frequencyLabel: "Frequency",
    selectOperationLabel: "select operation",
    selectFieldLabel: "select field",
    selectCalculationLabel: "select calculation",
    calculationLabel: "Calculation",
    calculationValueLabel: "Calculation Value",
    expiresOnLabel: "Expires on",
    paymentMethodLabel: "Payment Method",
    billingDetailsLabel: "Billing Details",
    manageFiltersLabel: "Manage Filters",
    dataModelNameLabel: "Data Model Name",
    filtersLabel: "Filters",
    schemaLabel: "Schema",
    addSchemaLabel: "Add Schema",
    addOptionLabel: "Add Option",
    inRelationLabel: "In Relation",
    manageStatTitle: "Manage stat for your model",
    operationLabel: "Operation",
    typeLabel: "Type",
    searchLabel: "Search",
    refreshLabel: "Refresh",
    addLabel: "Add",
    importLabel: "Import",
    exportLabel: "Export",
    paymentLabel: "Payment",
    commentsLabel: "Comments",
    addressLabel: "Address",
    assetsLabel: "Assests",
    deleteLabel: "Delete",
    permissionsLabel: "Permissions",
    streetNumberLabel: "Street Number",
    streetNameLabel: "Street Name",
    cityLabel: "City",
    postalCodeLabel: "Postal Code",
    stateLabel: "State",
    countryLabel: "Country",
    uploadFileLabel: "Upload File",
    addStatLabel: "Add Stat",
    addParentOrganizationLabel: "Add Parent Organization",
    addSubOrganizationLabel: "Add Sub Organization",
    addOrganizationLabel: "Add Organization",
    parentOrganizationMessage: "Please enter a name to continue",
    useDynamicMessage: "Use dynamic algorithm",
    signUpPreMessage: "Don't have an account?",
    signUpPostMessage: "Join now",
    exportDescription: "Export feature allows you to generate a csv file, sent over a downloadble link to your email given the current filters for this content. This may take several minutes depending the volume of your requested data",
    downloadCSVTitle: "Download sample csv file",
    downloadCSVDescription: "Upload a sample csv of data for this model and bulk import entries into your data pools. Please select a file after you hit submit",
    manageDataPoolProperties: "Manage Data Pool Properties",
    manageDataPoolLabel: "Manage Data Pool",
    addToListLabel: "Add to list",
    selectListLabel: "Select a list to add pools",
    schemaDialogTitle: "No Additional Schema created yet",
    schemaDialogSecondaryTitle: "Begin adding new fields to your data pool here at ease",
    organizationMessage: "Keep maintaining your data on our trusted network and choose one of the following plans. However, we recommend to reach out to our team to collaborate and develop specific solution for your data management and analytics needs",
    newOrganizationMessage: "Lets create an organization for you to finish signup",
    deleteHandlingMessage: "Once deleted from the data pool, this content will be removed from every instance and can not be recovered. Would you still like to continue?",
    errorHandlingMessage: "Oops! Looks like your last operation ended into an error displayed below. We advise you to log back in please. This incident has been reported to our team and bug hunt will shortly be in progress",
    signUpLabel: "Sign up here",
    upgradeMessage: "enjoying vermadev? kindly consider upgrading your account here",
    contentContainer: "manage your organization content data models here",
    relationsContainer: "manage your organization relations data models here",
    financialsContainer: "manage your organization finances data models here",
    listsContainer: "manage dynamic/static lists here",
    reportsContainer: "manage analytic reports here",
    settingsContainer: "manage user and organization settings here",
    integrationsContainer: "manage integrations with external applications here",
    addModelMessage: "add a new model here",
    addStatMessage: "add a new dynamic stat for this data model",
    searchMessage: "submit to search by name",
    tableView: "view data pools in table format",
    gridView: "view data pools in grid format",
    kanbanView: "view data pools in kanban format",
    modelMessage: "manage data model here",
    filtersMessage: "manage data model filters here",
    schemasMessage: "manage data model schema fields here",
    listsMessage: "add content to an existing list here",
    importMessage: "upload a document to bulk import data pools here",
    exportMessage: "get an email with a link to download a csv export file of this data model",
    refreshMessage: "refresh content to its natural state here",
    addPoolMessage: "add new content here",
    manageContentMessage: "manage content here",
    checkContentMessage: "click to submit updates",
    openContentMessage: "click to navigate into this content",
    sortMessage: "click to sort by this column",
    dragNDropMessage: "drag and drop card to change status",
    addListMessage: "add content to this list here",
    navigateBackToLists: "navigate back to lists",
    manageReportMessage: "manage report here",
    addParentOrganization: "add a parent organization to your current organization",
    addSubOrganization: "add a new sub organization to your current organization",
    currentOrganizationMessage: "current organization set to explore for this session",
    permissionMessage: "toggle to limit access to this feature across the team",
    pdfDownloadMessage: "click to download a pdf file of this content",
    csvDownloadMessage: "click to download a csv file of this content",
    errorMessage: "An error occured!",
    createSuccessMessage: "created successfully!",
    updatedSuccessMessage: "updated successfully!",
    removedSuccessMessage: "removed successfully!",
    exportMutationMessage: "Submitted export request successfully!",
    importMutationMessage: "Submitted import request successfully!",
    labelText: "Label",
};
export default EnglishContent;
