import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Paper, Grid, Typography, Tooltip, Switch, Loader, FormSnackBar, useTheme, useMediaQuery } from "../../mui";
import { ContentTypography, getContent } from "../../common/content";
import { OrganizationPermission, UserRole } from "../../../utils/enums";
import { camelCaseToWords } from "../../../utils/common";
export const PERMISSIONS = gql `
  query Permissions($id: ID!) {
    organization(id: $id) {
      id
      permissions {
        id
        permission
        feature
      }
    }
    currentUser {
      id
      role
    }
  }
`;
export const EDIT_PERMISSION = gql `
  mutation EditPermission($id: ID!, $data: permissionInput!) {
    editPermission(id: $id, data: $data) {
      id
    }
  }
`;
const Permissions = ({}) => {
    const id = localStorage.getItem("organizationId") || null;
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.down("sm"));
    const [permissions, setPermissions] = React.useState([]);
    const [snackBar, setSnackBar] = React.useState({
        open: false,
        message: "",
        type: "",
    });
    const { loading, error, data } = useQuery(PERMISSIONS, {
        variables: { id },
    });
    const [editPermission] = useMutation(EDIT_PERMISSION, {
        refetchQueries: ["Permissions", "OrganizationPermissions"],
    });
    React.useEffect(() => {
        if (data?.organization?.permissions) {
            setPermissions(data?.organization?.permissions);
        }
    }, [data]);
    if (loading)
        return _jsx(Loader, { size: 60 });
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const onChange = (permission) => editPermission({
        variables: {
            id: permission?.id,
            data: {
                permission: permission?.permission === OrganizationPermission.Visible
                    ? OrganizationPermission.Hidden
                    : OrganizationPermission.Visible,
            },
        },
        onCompleted: () => setSnackBar({
            open: true,
            message: `Updated feature permission successfully!`,
            type: "success",
        }),
        onError: () => setSnackBar({
            open: true,
            message: `${getContent("errorMessage")}`,
            type: "error",
        }),
    });
    const manageActionPermitted = [UserRole.Admin, UserRole.Staff].includes(data?.currentUser?.role);
    return (_jsxs(_Fragment, { children: [_jsx(FormSnackBar, { snackBar: snackBar, setSnackBar: setSnackBar }), _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { color: "primary", variant: "subtitle2", field: "permissionsLabel" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Grid, { container: true, spacing: 4, children: permissions?.map((permission, index) => (_jsx(Grid, { item: true, xs: smMatches ? 6 : 3, children: _jsx(Paper, { elevation: 6, children: _jsx(Tooltip, { title: getContent("permissionMessage"), children: _jsxs(Grid, { container: true, spacing: 1, sx: { p: 2 }, children: [_jsx(Grid, { item: true, xs: 12, textAlign: "center", children: _jsx(Typography, { variant: "button", children: camelCaseToWords(permission?.feature) || "–" }) }), _jsx(Grid, { item: true, xs: 12, textAlign: "center", children: _jsx(Switch, { size: "large", color: "primary", disabled: permission?.permission === OrganizationPermission.Blocked || !manageActionPermitted, checked: permission?.permission === OrganizationPermission.Visible, onChange: (event) => onChange(permission) }) })] }) }) }) }, index))) }) })] })] }));
};
export default Permissions;
