import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Card, CardContent, Grid, Button, Divider, FormDialog, CircularProgress, FormSnackBar, useTheme, useMediaQuery, Typography, Tooltip, TextField, } from "../../mui";
import { ContentActions, getContent } from "../../common/content";
import { FormConfirm } from "../../common/forms";
import defaults from "../../../utils/defaults";
import constants from "../../../utils/constants";
import RouteConstants from "../../../routes/RouteConstants";
export const PARENT_ORGANIZATION = gql `
  query ParentOrganization($id: ID!) {
    organization(id: $id) {
      id
      name
      parentOrganization {
        id
        name
      }
      subOrganizations {
        id
        name
      }
    }
    currentUser {
      id
      role
    }
  }
`;
export const ADD_ORGANIZATION = gql `
  mutation AddOrganization($data: organizationInput!) {
    addOrganization(data: $data) {
      id
      name
    }
  }
`;
const ParentOrganization = ({}) => {
    const id = localStorage.getItem("organizationId") || null;
    const history = useHistory();
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.down("sm"));
    const [organization, setOrganization] = React.useState(defaults.organizations);
    const [name, setName] = React.useState("");
    const [snackBar, setSnackBar] = React.useState({
        open: false,
        message: "",
        type: "",
    });
    const [dialog, setDialog] = React.useState({
        open: false,
        message: "",
        type: "",
    });
    const { loading, error, data } = useQuery(PARENT_ORGANIZATION, {
        variables: { id },
    });
    const [addOrganization] = useMutation(ADD_ORGANIZATION, {
        refetchQueries: ["ParentOrganization", "OrganizationPermissions"],
    });
    React.useEffect(() => {
        setOrganization(data?.organization || defaults.organizations);
    }, [data]);
    if (loading)
        return (_jsx(Card, { children: _jsx(CardContent, { children: _jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, sx: { margin: "20%" }, children: _jsx(CircularProgress, { size: 40, color: "primary" }) }) }) }) }));
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const onAddOrganization = () => {
        if (name === "") {
            setDialog({
                ...dialog,
                message: getContent("parentOrganizationMessage"),
            });
        }
        else {
            let data = {
                name,
            };
            if (dialog?.type === constants.PARENT_ORGANIZATION_TYPE.sub) {
                data.parentOrganizationId = id;
            }
            if (dialog?.type === constants.PARENT_ORGANIZATION_TYPE.parent) {
                data.subOrganizationId = id;
            }
            addOrganization({
                variables: {
                    data,
                },
                onCompleted: () => {
                    setDialog({
                        open: false,
                        message: "",
                        type: "",
                    });
                    setSnackBar({
                        open: true,
                        message: `Added new organization successfully!`,
                        type: "success",
                    });
                },
                onError: (error) => {
                    setDialog({
                        ...dialog,
                        message: error?.message,
                    });
                },
            });
        }
    };
    const onDialogChange = () => setDialog({
        open: !dialog?.open,
        message: "",
        type: "",
    });
    return (_jsxs(_Fragment, { children: [_jsx(FormSnackBar, { snackBar: snackBar, setSnackBar: setSnackBar }), _jsx(FormDialog, { maxWidth: "md", open: dialog?.open, setOpen: onDialogChange, title: getContent("addOrganizationLabel"), actions: _jsx(ContentActions, { onClose: onDialogChange, onSave: onAddOrganization }), children: _jsxs(Grid, { container: true, spacing: 2, alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { item: true, xs: 9, children: _jsx(TextField, { fullWidth: true, value: name, label: getContent("organizationNameLabel"), onChange: (e) => {
                                    setDialog({
                                        ...dialog,
                                        message: "",
                                    });
                                    setName(e?.target?.value);
                                }, size: "medium" }) }), _jsx(Grid, { item: true, xs: 9, children: _jsx(Typography, { color: "error", variant: "subtitle2", children: dialog?.message }) })] }) }), _jsx(Card, { children: _jsx(CardContent, { children: _jsxs(Grid, { container: true, spacing: 2, alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { item: true, sx: { m: 2 }, xs: 12, children: _jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, children: organization?.parentOrganization ? (_jsx(FormConfirm, { title: getContent("exploreLabel"), description: getContent("exploreMessage"), handleSubmit: () => {
                                                localStorage.setItem("organizationId", organization?.parentOrganization?.id);
                                                history.push(RouteConstants.Dashboard.path);
                                            }, children: _jsx(Button, { variant: organization?.parentOrganization?.id === id ? "outlined" : "text", children: organization?.parentOrganization?.name || "–" }) })) : (_jsx(Tooltip, { title: getContent("addParentOrganization"), children: _jsx(Button, { sx: { textTransform: "none" }, onClick: () => setDialog({
                                                    open: true,
                                                    message: "",
                                                    type: constants.PARENT_ORGANIZATION_TYPE.parent,
                                                }), children: getContent("addParentOrganizationLabel") }) })) }) }) }), _jsx(Divider, { orientation: "vertical", flexItem: true, style: { height: "96px" } }), _jsx(Grid, { item: true, sx: { m: 2 }, xs: 12, children: _jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, children: _jsx(Tooltip, { title: getContent("currentOrganizationMessage"), children: _jsx(Button, { variant: "outlined", children: organization?.name || "–" }) }) }) }) }), _jsx(Divider, { orientation: "vertical", flexItem: true, style: { height: "88px" } }), _jsx(Grid, { item: true, sx: { m: 2 }, xs: 12, children: _jsxs(Grid, { container: true, alignItems: "center", justifyContent: "center", children: [organization?.subOrganizations?.map((subOrganization) => (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, children: _jsx(FormConfirm, { title: getContent("exploreLabel"), description: getContent("exploreMessage"), handleSubmit: () => {
                                                            localStorage.setItem("organizationId", subOrganization?.id);
                                                            history.push(RouteConstants.Dashboard.path);
                                                        }, children: _jsx(Button, { variant: subOrganization?.id === id ? "outlined" : "text", children: subOrganization?.name || "–" }) }) }), _jsx(Grid, { item: true, children: _jsx(Divider, { style: { width: "64px" } }) })] }))), _jsx(Grid, { item: true, children: _jsx(Tooltip, { title: getContent("addSubOrganization"), children: _jsx(Button, { sx: { textTransform: "none" }, onClick: () => setDialog({
                                                        open: true,
                                                        message: "",
                                                        type: constants.PARENT_ORGANIZATION_TYPE.sub,
                                                    }), children: getContent("addSubOrganizationLabel") }) }) })] }) })] }) }) })] }));
};
export default ParentOrganization;
