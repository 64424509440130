import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardContent, Typography, Button, TextField, Grid, Logo, InputAdornment, IconButton, CircularProgress, } from "../mui";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import BottomFooter from "./BottomFooter";
import { ContentTypography } from "../common/content";
import { forgotPassword, forgotPasswordSubmit } from "../../utils/auth/graph_helper";
import { LandingPath } from "../../utils/enums";
import RouteConstants from "../../routes/RouteConstants";
import PinInput from "react-pin-input";
const ResetPassword = ({ setMainView }) => {
    const history = useHistory();
    const search = history.location.search;
    const params = new URLSearchParams(search);
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [requestSent, setRequestSent] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const resetPasswordCall = async () => {
        const response = await forgotPassword(email);
        if (response?.status == "error") {
            setErrorMessage(response?.result?.message);
            setError(true);
            setLoading(false);
        }
        else {
            setRequestSent(true);
        }
    };
    const submitPasswordCall = async () => {
        setLoading(true);
        if (confirmPassword !== newPassword) {
            setErrorMessage("Confirm Password does not match Password, please verify and try again.");
            setError(true);
            setLoading(false);
        }
        const response = await forgotPasswordSubmit(email, code, newPassword);
        if (response?.status == "error") {
            setErrorMessage(response?.result?.message);
            setError(true);
            setLoading(false);
        }
        else {
            setLoading(false);
            return history.push(RouteConstants.Login.path);
        }
    };
    return (_jsx(Card, { sx: { maxWidth: 400, mt: 16, p: 1 }, display: "flex", children: _jsxs(CardContent, { children: [_jsx(Logo, { loggedIn: false }), _jsx(Grid, { container: true, spacing: 2, children: _jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { variant: "subtitle2", color: "secondary", field: "companySlogan" }) }) }), _jsxs(Grid, { container: true, spacing: 2, alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(TextField, { fullWidth: true, size: "small", error: error, label: _jsx(ContentTypography, { field: "emailLabel" }), variant: "outlined", value: email, onChange: (e) => setEmail(e.target.value) }) }), requestSent && (_jsx(Grid, { item: true, xs: 12, children: _jsx(PinInput, { length: 6, initialValue: "", secret: true, secretDelay: 100, onChange: (value, index) => setCode(value), type: "numeric", inputMode: "number", inputFocusStyle: { borderColor: "blue" }, onComplete: (value, index) => null, autoSelect: true, regexCriteria: /^[ A-Za-z0-9_@./#&+-]*$/, style: { display: "ruby-text" } }) })), requestSent && (_jsx(Grid, { item: true, xs: 12, children: _jsx(TextField, { fullWidth: true, size: "small", error: error, label: _jsx(ContentTypography, { field: "newPasswordLabel" }), type: showPassword ? "text" : "password", variant: "outlined", value: newPassword, onChange: (e) => setNewPassword(e.target.value), InputProps: {
                                    endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { onClick: () => setShowPassword((show) => !show), edge: "end", children: showPassword ? _jsx(VisibilityOff, {}) : _jsx(Visibility, {}) }) })),
                                } }) })), requestSent && (_jsx(Grid, { item: true, xs: 12, children: _jsx(TextField, { fullWidth: true, size: "small", error: error, label: _jsx(ContentTypography, { field: "confirmPasswordLabel" }), type: showConfirmPassword ? "text" : "password", variant: "outlined", value: confirmPassword, onChange: (e) => setConfirmPassword(e.target.value), InputProps: {
                                    endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { onClick: () => setShowConfirmPassword((show) => !show), edge: "end", children: showConfirmPassword ? _jsx(VisibilityOff, {}) : _jsx(Visibility, {}) }) })),
                                } }) })), error && (_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "body2", color: "error", component: "p", children: errorMessage }) })), requestSent ? (_jsx(Grid, { item: true, xs: 12, children: _jsxs(Button, { variant: "contained", color: "primary", fullWidth: true, onClick: submitPasswordCall, children: [_jsx(ContentTypography, { field: "resetPassLabel" }), loading && _jsx(CircularProgress, { size: 24, color: "secondary" })] }) })) : (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Button, { variant: "contained", color: "primary", fullWidth: true, onClick: resetPasswordCall, children: _jsx(ContentTypography, { field: "verifyCodeDescription" }) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Button, { color: "primary", variant: "outlined", fullWidth: true, onClick: () => setRequestSent(true), children: _jsx(ContentTypography, { field: "confirmCodeDescription" }) }) })] })), _jsx(Grid, { item: true, xs: 12, children: _jsx(Button, { color: "primary", variant: "outlined", fullWidth: true, onClick: () => {
                                    if (params.has("view")) {
                                        params.delete("view");
                                        history.replace({
                                            search: params.toString(),
                                        });
                                    }
                                    setMainView(LandingPath.login);
                                }, children: _jsx(ContentTypography, { field: "backToLoginDescription" }) }) }), _jsx(Grid, { item: true, children: _jsx(BottomFooter, {}) })] })] }) }));
};
export default ResetPassword;
