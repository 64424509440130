import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Typography, Grid, Paper, Divider, Loader, ErrorHandling, useMediaQuery, useTheme } from "../components/mui";
import { ContentTypography } from "../components/common/content";
import AppLayout from "./common/AppLayout";
import { AppMode } from "../utils/enums";
import ColorModeContext from "../routes/ColorModeContext";
import { capitalizeSentence } from "../utils/common";
import RouteConstants from "../routes/RouteConstants";
import constants from "../utils/constants";
const USER_ACTIVITIES = gql `
  query UserActivties {
    currentUser {
      id
      platformSettings {
        mode
      }
    }
    activities {
      label
      value
      path
    }
    currentOrganization {
      id
      primaryTheme
      notifications {
        id
        label
      }
    }
  }
`;
const DashboardContainer = (props) => {
    const history = useHistory();
    const theme = useTheme();
    const mdMatches = useMediaQuery(theme.breakpoints.down("md"));
    const colorMode = React.useContext(ColorModeContext);
    const { loading, error, data } = useQuery(USER_ACTIVITIES);
    React.useEffect(() => {
        const userId = localStorage.getItem("userId");
        const organizationId = localStorage.getItem("organizationId");
        if (!userId || !organizationId) {
            return history.push(RouteConstants.Login.path);
        }
    }, []);
    React.useEffect(() => {
        if (data) {
            colorMode.defaultMode({
                mode: data?.currentUser?.platformSettings?.mode || AppMode.light,
            });
            colorMode.togglePrimaryTheme({
                color: data?.currentOrganization?.primaryTheme || constants.DEFAULT_PRIMARY_THEME,
            });
        }
    }, [data]);
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(ErrorHandling, { error: error });
    return (_jsx(AppLayout, { children: _jsx(Grid, { container: true, sx: { p: mdMatches ? 1 : 4 }, spacing: 1, direction: "row", children: data?.activities?.length === 0 && data?.currentOrganization?.notifications?.length === 0 ? (_jsx(Grid, { item: true, children: _jsx(ContentTypography, { align: "center", variant: "h2", color: "primary", m: mdMatches ? 10 : 24, field: "dashboardWelcomeLabel" }) })) : (_jsxs(React.Fragment, { children: [_jsx(Grid, { item: true, sx: { height: "48vh", overflow: "auto" }, xs: 12, children: _jsx(Grid, { container: true, spacing: 2, children: data?.activities?.map((activity, index) => (_jsx(Grid, { item: true, children: _jsxs(Paper, { elevation: 6, sx: { minWidth: 128, cursor: "pointer", p: 3 }, onClick: () => (activity.path ? history.push(activity?.path) : null), children: [_jsx(Typography, { align: "center", sx: { m: 1 }, color: "primary", variant: "h5", fontWeight: "bold", children: activity?.value }), _jsx(Typography, { sx: { mt: 1 }, color: "text.secondary", fontWeight: "semi-bold", variant: "subtitle1", children: capitalizeSentence(activity?.label) })] }) }, index))) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { my: 2, variant: "subtitle2", color: "primary", fontWeight: "bold", field: "dashboardNotificationsLabel" }) }), data?.currentOrganization?.notifications?.length === 0 && (_jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { variant: "subtitle2", field: "noDataDescription" }) })), _jsx("div", { style: { height: "24vh", width: "100%", padding: 16, overflow: "auto" }, children: data?.currentOrganization?.notifications?.map((notification, index) => (_jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "subtitle2", children: notification.label }), _jsx(Divider, { flexItem: true, sx: { my: 2 } })] }, index))) })] }) })] })) }) }));
};
export default DashboardContainer;
