export const validEmail = (email) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
export const isNumber = (value) => value && !isNaN(value);
export const grabAge = (dateOfBirth) => {
    var today = new Date();
    var birthDate = new Date(dateOfBirth);
    var age = today?.getFullYear() - birthDate?.getFullYear();
    var m = today?.getMonth() - birthDate?.getMonth();
    if (m < 0 || (m === 0 && today?.getDate() < birthDate?.getDate())) {
        age--;
    }
    return age;
};
export const camelCaseToWords = (value) => {
    if (!value || value === "") {
        return "–";
    }
    const result = value?.replace(/([A-Z])/g, " $1");
    return result?.charAt(0)?.toUpperCase() + result?.slice(1);
};
export const capitalizeSentence = (value) => {
    if (!value || value === "") {
        return "–";
    }
    return value?.replace(/\w\S*/g, (txt) => txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase());
};
export const largeNumberLabel = (value) => {
    if (!value || value === "" || value === 0) {
        return "–";
    }
    const number = parseFloat(value);
    let notation = "standard";
    if (number > 99999)
        notation = "compact";
    const formatter = Intl.NumberFormat("en", { notation });
    return formatter.format(number);
};
export const getRandomArbitrary = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
};
export const formatPhoneNumber = (value) => {
    if (!value || value === "") {
        return "–";
    }
    var cleaned = ("" + value)?.replace(/\D/g, "");
    var match = cleaned?.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "–", match[4]].join("");
    }
    return null;
};
export const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow)
        newWindow.opener = null;
};
