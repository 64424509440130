import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { FormSnackBar, Grid, ErrorHandling } from "../../mui";
import Address from "../../common/sections/Address";
import defaults from "../../../utils/defaults";
import { UserRole } from "../../../utils/enums";
import { MANAGE_ADDRESS } from "../../Pools/PoolSections";
import AppSubscription from "./AppSubscription";
import OrganizationProfile from "./OrganizationProfile";
export const ORGANIZATION_TAB = gql `
  query OrganizationTab($id: ID!) {
    organization(id: $id) {
      id
      customerType
      subscriptionStartDate
      subscriptionEndDate
      subscriptionPauseDate
      address {
        id
        streetNumber
        streetName
        city
        state
        country
        postalCode
        longitude
        latitude
      }
    }
    currentUser {
      id
      role
    }
  }
`;
const OrganizationTab = ({}) => {
    const id = localStorage.getItem("organizationId") || null;
    const [organization, setOrganization] = React.useState(defaults.organizations);
    const [snackBar, setSnackBar] = React.useState({
        open: false,
        message: "",
        type: "",
    });
    const { loading, error, data } = useQuery(ORGANIZATION_TAB, {
        variables: { id },
    });
    const [manageAddress] = useMutation(MANAGE_ADDRESS, {
        refetchQueries: ["OrganizationTab"],
    });
    React.useEffect(() => {
        setOrganization(data?.organization || defaults.organizations);
    }, [data]);
    if (loading)
        return null;
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const manageOrganizationAddress = (address) => manageAddress({
        variables: {
            data: {
                id: address?.id,
                streetNumber: address?.streetNumber,
                streetName: address?.streetName,
                city: address?.city,
                country: address?.country,
                postalCode: address?.postalCode,
                state: address?.state,
                organizationId: organization?.id,
            },
        },
        onCompleted: () => setSnackBar({
            open: true,
            message: `Updated organization address successfully!`,
            type: "success",
        }),
        onError: () => setSnackBar({
            open: true,
            message: `${getContent("errorMessage")}`,
            type: "error",
        }),
    });
    const manageActionPermitted = [UserRole.Admin, UserRole.Staff].includes(data?.currentUser?.role);
    return (_jsxs(_Fragment, { children: [_jsx(FormSnackBar, { snackBar: snackBar, setSnackBar: setSnackBar }), _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, children: _jsx(OrganizationProfile, {}) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(AppSubscription, { data: organization || null }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Address, { onSave: manageActionPermitted ? manageOrganizationAddress : null, data: organization?.address || defaults.address, refetchQueries: ["OrganizationTab"] }) })] })] }));
};
export default OrganizationTab;
