import { jsx as _jsx } from "react/jsx-runtime";
import { Stack } from "../../mui";
import { PieChart } from "@mui/x-charts/PieChart";
const SimplePieChart = ({ width = 400, height = 400, data = [], xAxis = "label", yAxis = "value" }) => (_jsx(Stack, { sx: { width: "100%" }, children: _jsx(PieChart, { margin: { top: 100, bottom: 100, left: 50, right: 100 }, slotProps: {
            legend: {
                direction: "column",
                position: { vertical: "middle", horizontal: "right" },
                itemMarkWidth: 20,
                itemMarkHeight: 2,
                markGap: 5,
                itemGap: 10,
            },
        }, series: [
            {
                data,
                cornerRadius: 8,
                innerRadius: 1,
                outerRadius: 100,
            },
        ], width: width, height: height }) }));
export default SimplePieChart;
