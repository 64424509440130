import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { Grid, FormDialog, TextField, Loader, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, ErrorHandling, } from "../../components/mui";
import { CalculationType } from "../../utils/enums";
import { ContentActions, ContentTypography } from "../common/content";
import { ModelsSelect, FieldsSelect } from "../common/selects";
import constants from "../../utils/constants";
import defaults from "../../utils/defaults";
import { camelCaseToWords } from "../../utils/common";
const MANAGE_LIST = gql `
  query ManageList($id: ID!) {
    list(id: $id) {
      id
      createdAt
      label
      star
      modelName
      fieldName
      calculation
      calculationValue
      dynamic
    }
  }
`;
const ADD_LIST = gql `
  mutation AddList($data: listInput!) {
    addList(data: $data) {
      id
    }
  }
`;
const EDIT_LIST = gql `
  mutation EditList($id: ID!, $data: listInput!) {
    editList(id: $id, data: $data) {
      id
    }
  }
`;
const REMOVE_LIST = gql `
  mutation RemoveList($id: ID!) {
    removeList(id: $id) {
      success
    }
  }
`;
const ManageListDialog = ({ organizationId = null, actionDialogState = null, setActionDialogState = () => null, onDelete = () => null, }) => {
    const [list, setList] = React.useState(defaults.lists);
    const [getList, { loading, error, data }] = useLazyQuery(MANAGE_LIST);
    React.useEffect(() => {
        if (actionDialogState?.id) {
            getList({
                variables: { id: actionDialogState?.id },
            });
        }
        else {
            setList(defaults.lists);
        }
    }, [actionDialogState]);
    React.useEffect(() => {
        setList(data?.list || defaults.lists);
    }, [data]);
    const [addList] = useMutation(ADD_LIST, {
        refetchQueries: ["OrganizationLists"],
    });
    const [editList] = useMutation(EDIT_LIST, {
        refetchQueries: ["OrganizationLists"],
    });
    const [removeList] = useMutation(REMOVE_LIST, {
        refetchQueries: ["OrganizationLists"],
    });
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const onListSave = () => {
        let data = { ...list, organizationId };
        if (actionDialogState.action === constants.OPERATION_TYPE.ADD) {
            addList({
                variables: { data },
            });
        }
        if (actionDialogState.action === constants.OPERATION_TYPE.EDIT) {
            const id = data.id;
            delete data?.id;
            delete data?.createdAt;
            delete data?.__typename;
            editList({
                variables: { id, data },
            });
        }
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onListEdit = (event) => {
        let temp = { ...list };
        if (event.target.name === "dynamic") {
            temp.dynamic = event.target.checked;
        }
        else {
            temp[event.target.name] = event.target.value;
        }
        setList(temp);
    };
    const onListDelete = () => {
        removeList({
            variables: { id: list?.id },
        });
        onDelete();
    };
    return (_jsx(FormDialog, { open: actionDialogState?.open || false, setOpen: () => setActionDialogState({
            ...actionDialogState,
            open: false,
        }), title: `${actionDialogState.action} List`, actions: _jsx(ContentActions, { onClose: () => setActionDialogState({
                ...actionDialogState,
                open: false,
            }), onSave: onListSave, onDelete: actionDialogState.action === constants.OPERATION_TYPE.EDIT ? onListDelete : null }), children: _jsxs(Grid, { container: true, spacing: 4, children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(TextField, { fullWidth: true, label: _jsx(ContentTypography, { field: "labelText" }), name: "label", value: list?.label, onChange: onListEdit, size: "small" }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormControlLabel, { control: _jsx(Switch, { color: "primary", name: "dynamic", checked: list?.dynamic, onChange: onListEdit }), label: _jsx(ContentTypography, { field: "useDynamicMessage" }) }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(ModelsSelect, { fullWidth: true, name: "modelName", value: list?.modelName, label: _jsx(ContentTypography, { field: "selectModelLabel" }), onChange: onListEdit, size: "small" }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FieldsSelect, { fullWidth: true, name: "fieldName", value: list?.fieldName, label: _jsx(ContentTypography, { field: "selectFieldLabel" }), onChange: onListEdit, size: "small", modelName: list?.modelName }) }), _jsx(Grid, { item: true, xs: 6, children: _jsxs(FormControl, { style: { width: "100%" }, children: [_jsx(InputLabel, { children: "Calculation" }), _jsx(Select, { name: "calculation", value: list?.calculation, label: _jsx(ContentTypography, { field: "selectCalculationLabel" }), onChange: onListEdit, size: "small", children: Object.keys(CalculationType)?.map((type, index) => (_jsx(MenuItem, { value: CalculationType[type], children: camelCaseToWords(type) }, index))) })] }) }), list?.calculation && list?.calculation !== "" ? (_jsx(Grid, { item: true, xs: 6, children: _jsx(TextField, { fullWidth: true, name: "calculationValue", value: list?.calculationValue, label: _jsx(ContentTypography, { field: "calculationValueLabel" }), onChange: onListEdit, size: "small" }) })) : null] }) }));
};
export default ManageListDialog;
