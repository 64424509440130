import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "../components/mui";
import NavigationHeader from "../components/Navigation/NavigationHeader";
import SignUp from "../components/Landing/SignUp";
import Login from "../components/Landing/Login";
import ResetPassword from "../components/Landing/ResetPassword";
import { LandingPath } from "../utils/enums";
import { getRandomArbitrary } from "../utils/common";
const AppLogin = (props) => {
    const history = useHistory();
    const search = history.location.search;
    const params = new URLSearchParams(search);
    const viewParam = params.get("view");
    const [view, setMainView] = useState(LandingPath.login);
    const assets = [
        "BgGridScape.png",
        "black_sand_beach.jpeg",
        "crafty.jpeg",
        "grid_data.jpeg",
        "middle_of_city.jpeg",
        "painted.jpeg",
        "rainy_city.jpeg",
        "stars.jpeg",
        "two_shade_car.jpeg",
    ];
    React.useEffect(() => {
        if (viewParam === LandingPath.confirmuser || viewParam === LandingPath.signup) {
            setMainView(LandingPath.signup);
        }
    }, [viewParam]);
    const renderSwitch = (view) => {
        switch (view) {
            case LandingPath.login:
                return _jsx(Login, { setMainView: setMainView });
            case LandingPath.signup:
                return _jsx(SignUp, { setMainView: setMainView, viewParam: viewParam });
            case LandingPath.resetpassword:
                return _jsx(ResetPassword, { setMainView: setMainView });
            default:
                return _jsx(Login, { setMainView: setMainView });
        }
    };
    return (_jsxs("div", { style: {
            backgroundImage: `url(img/${assets[getRandomArbitrary(0, 8)]})`,
            width: "100%",
            height: "100vh",
            backgroundSize: "cover",
        }, children: [_jsx(NavigationHeader, { setMainView: setMainView, view: view }), _jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, children: renderSwitch(view) }) })] }));
};
export default AppLogin;
