import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { FormSnackBar, Loader, ErrorHandling } from "../../components/mui";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { ManagePoolContentDialog } from "../common/dialogs";
import { FormContent } from "../common/forms";
import { FormFieldOrder } from "../../utils/enums";
import constants from "../../utils/constants";
import defaults from "../../utils/defaults";
import { getContent } from "../common/content";
const LEAD_FRAGMENT = gql `
  fragment LeadFragment on lead {
    id
    createdAt
    updatedAt
    name
    description
    status
    leadType
    source
    amount
    projectedClosingDate
    expectedRevenue
    probability
    nextStep
    poolId
  }
`;
const LEADS = gql `
  query LeadsView($filters: queryFilters!) {
    __type(name: "lead") {
      name
      fields {
        name
        type {
          name
          kind
          enumValues {
            name
          }
        }
      }
    }
    leads(filters: $filters) {
      count
      page
      rowsPerPage
      order
      orderBy
      search
      view
      headers {
        label
        key
        component
        scope
        align
        numeric
        width
      }
      list {
        ...LeadFragment
      }
    }
  }
  ${LEAD_FRAGMENT}
`;
const LEAD = gql `
  query LeadQuery($id: ID!) {
    lead(id: $id) {
      id
      createdAt
      updatedAt
      name
      description
      status
      leadType
      source
      amount
      projectedClosingDate
      expectedRevenue
      probability
      nextStep
      poolId
      accountOwner {
        id
      }
      client {
        id
      }
      campaign {
        id
      }
      readOnly {
        label
        value
      }
      contents {
        id
        key
        value
        schemaId
        poolId
      }
    }
  }
`;
const ADD_LEAD = gql `
  mutation AddLead($data: leadInput!) {
    addLead(data: $data) {
      ...LeadFragment
    }
  }
  ${LEAD_FRAGMENT}
`;
const EDIT_LEAD = gql `
  mutation EditLead($id: ID!, $data: leadInput!) {
    editLead(id: $id, data: $data) {
      ...LeadFragment
    }
  }
  ${LEAD_FRAGMENT}
`;
const REMOVE_LEAD = gql `
  mutation RemoveLead($id: ID!) {
    removeLead(id: $id) {
      success
    }
  }
`;
const EXPORT_LEADS = gql `
  mutation ExportLeads($filters: queryFilters!) {
    exportLeads(filters: $filters) {
      id
    }
  }
`;
const IMPORT_LEADS = gql `
  mutation ImportLeads($data: usageInput!) {
    importLeads(data: $data) {
      id
    }
  }
`;
const Leads = ({ organizationId = null, organizationModelId = null, modelName = null, category = null, permissions = [], }) => {
    const notIncludedFields = [
        "id",
        "selected",
        "edited",
        "createdAt",
        "updatedAt",
        "poolId",
        "contents",
        "readOnly",
        "__typename",
    ];
    const [lead, setLead] = React.useState(defaults.lead);
    const [actionDialogState, setActionDialogState] = React.useState({
        open: false,
        id: null,
        action: null,
    });
    const [snackBar, setSnackBar] = React.useState({
        open: false,
        message: "",
        type: "",
    });
    const { loading, error, data, refetch } = useQuery(LEADS, {
        variables: {
            filters: {},
        },
    });
    const [getLead, { data: leadData }] = useLazyQuery(LEAD);
    const [addLead] = useMutation(ADD_LEAD, {
        refetchQueries: ["LeadsView"],
    });
    const [editLead] = useMutation(EDIT_LEAD, {
        refetchQueries: ["LeadsView"],
    });
    const [removeLead] = useMutation(REMOVE_LEAD, {
        refetchQueries: ["LeadsView"],
    });
    const [exportLeads] = useMutation(EXPORT_LEADS);
    const [importLeads] = useMutation(IMPORT_LEADS, {
        refetchQueries: ["LeadsView"],
    });
    React.useEffect(() => {
        if (actionDialogState?.action === constants.OPERATION_TYPE.EDIT && actionDialogState?.id) {
            const id = actionDialogState?.id;
            getLead({
                variables: { id },
            });
        }
    }, [actionDialogState]);
    React.useEffect(() => {
        if (leadData?.lead) {
            setLead({
                ...leadData?.lead,
                client: leadData?.lead?.client?.id || "",
                campaign: leadData?.lead?.campaign?.id || "",
                accountOwner: leadData?.lead?.accountOwner?.id || "",
            });
        }
    }, [leadData]);
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const onContentAdd = (pool) => {
        const contents = pool?.contents?.map((content) => ({
            id: content.id,
            poolId: content.poolId,
            schemaId: content.schemaId,
            key: content.key,
            value: content.value,
        })) || [];
        let data = {
            ...pool,
            organizationId,
            contents,
            clientId: pool?.client,
            accountOwnerId: pool?.accountOwner,
            campaignId: pool?.campaign,
            amount: parseFloat(pool?.amount),
            expectedRevenue: parseFloat(pool?.expectedRevenue),
            probability: parseFloat(pool?.probability),
        };
        delete data?.client;
        delete data?.campaign;
        delete data?.accountOwner;
        delete data?.readOnly;
        addLead({
            variables: {
                data,
            },
            onCompleted: ({ addLead }) => setSnackBar({
                open: true,
                message: `${addLead?.name} ${getContent("createSuccessMessage")}`,
                type: "success",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onContentEdit = (pool) => {
        const contents = pool?.contents?.map((content) => ({
            id: content.id,
            poolId: content.poolId,
            schemaId: content.schemaId,
            key: content.key,
            value: content.value,
        })) || [];
        let data = {
            ...pool,
            organizationId,
            contents,
            clientId: pool?.client,
            campaignId: pool?.campaign,
            accountOwnerId: pool?.accountOwner,
            amount: parseFloat(pool?.amount),
            expectedRevenue: parseFloat(pool?.expectedRevenue),
            probability: parseFloat(pool?.probability),
            projectedClosingDate: new Date(pool?.projectedClosingDate),
        };
        delete data?.id;
        delete data?.createdAt;
        delete data?.updatedAt;
        delete data?.selected;
        delete data?.editable;
        delete data?.poolId;
        delete data?.campaign;
        delete data?.readOnly;
        delete data?.client;
        delete data?.accountOwner;
        delete data?.edited;
        delete data?.__typename;
        editLead({
            variables: {
                id: pool.id,
                data,
            },
            onCompleted: ({ editLead }) => setSnackBar({
                open: true,
                message: `${editLead?.name} ${getContent("updatedSuccessMessage")}`,
                type: "success",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onContentDelete = () => {
        removeLead({
            variables: { id: lead?.id },
            onCompleted: () => setSnackBar({
                open: true,
                message: `${lead?.name} ${getContent("removedSuccessMessage")}`,
                type: "info",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onDialogChange = ({ open = false, id = null, action = null }) => {
        setActionDialogState({
            open,
            id,
            action,
        });
    };
    const handleImport = ({ uploadAsset }) => {
        if (uploadAsset) {
            importLeads({
                variables: {
                    data: { uploadAsset },
                },
                onCompleted: () => setSnackBar({
                    open: true,
                    message: `${getContent("importMutationMessage")}`,
                    type: "info",
                }),
                onError: () => setSnackBar({
                    open: true,
                    message: `${getContent("errorMessage")}`,
                    type: "error",
                }),
            });
        }
    };
    const handleExport = () => {
        exportLeads({
            variables: {
                filters: {
                    order: data?.leads?.order,
                    orderBy: data?.leads?.orderBy,
                    search: data?.leads?.search,
                },
            },
            onCompleted: () => setSnackBar({
                open: true,
                message: `${getContent("exportMutationMessage")}`,
                type: "info",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormSnackBar, { snackBar: snackBar, setSnackBar: setSnackBar }), _jsx(ManagePoolContentDialog, { displayPool: true, poolData: lead || null, poolId: lead?.poolId || null, defaultSchema: defaults.leads, organizationModelId: organizationModelId, open: actionDialogState?.open || false, onDialogChange: onDialogChange, onSave: actionDialogState.action === constants.OPERATION_TYPE.ADD ? onContentAdd : onContentEdit, notIncludedFields: notIncludedFields, fields: data?.__type?.fields, operationType: actionDialogState?.action || constants.OPERATION_TYPE.ADD, onDelete: actionDialogState.action === constants.OPERATION_TYPE.EDIT ? onContentDelete : null, permissions: permissions }), _jsx(FormContent, { pool: false, organizationModelId: organizationModelId || null, modelName: modelName, category: category, headers: data?.leads?.headers || [], data: data?.leads?.list || [], count: data?.leads?.count || 0, page: data?.leads?.page || 0, defaultView: data?.leads?.view || null, order: data?.leads?.order || FormFieldOrder.asc, orderBy: data?.leads?.orderBy || constants.DEFAULT_SORT, search: data?.leads?.search || "", defaultOrder: constants.DEFAULT_ORDER, rowsPerPage: data?.leads?.rowsPerPage || 0, editMutation: onContentEdit, refetch: refetch, defaultSchema: defaults.leads, notIncludedFields: notIncludedFields, fields: data?.__type?.fields, actionDialogState: actionDialogState?.open || false, setActionDialogState: onDialogChange, handleExport: handleExport, handleImport: handleImport, loading: loading, error: error, permissions: permissions })] }));
};
export default Leads;
