import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { Grid, Button, Tooltip } from "../../mui";
import { FileDownload } from "@mui/icons-material";
import ContentTypography, { getContent } from "./ContentTypography";
import generatePDF from "react-to-pdf";
const ContentToPDF = ({ disabled = false, filename = "sample.pdf", children }) => {
    const targetRef = React.useRef();
    return (_jsxs(_Fragment, { children: [_jsx(Grid, { container: true, alignItems: "flex-end", justifyContent: "flex-end", children: _jsx(Grid, { item: true, children: _jsx(Tooltip, { placement: "left-start", title: getContent("pdfDownloadMessage"), children: _jsxs(Button, { disabled: disabled, onClick: () => generatePDF(targetRef, { filename }), color: "primary", variant: "outlined", size: "small", children: [_jsx(FileDownload, { fontSize: "small" }), _jsx(ContentTypography, { color: "secondary", sx: { textTransform: "none" }, variant: "subtitle2", field: "downloadPDFLabel" })] }) }) }) }), _jsx("div", { style: { width: "100%" }, ref: targetRef, children: children })] }));
};
export default ContentToPDF;
