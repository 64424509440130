const RouteConstants = {
    Login: {
        key: `${Math.random()}ROUTE_`,
        mainNav: true,
        path: "/login",
        header: "Login",
    },
    Settings: {
        key: `${Math.random()}ROUTE_`,
        mainNav: false,
        path: "/settings",
        header: "Settings",
    },
    Integrations: {
        key: `${Math.random()}ROUTE_`,
        mainNav: false,
        path: "/integrations",
        header: "Integrations",
    },
    Content: {
        key: `${Math.random()}ROUTE_`,
        mainNav: false,
        path: "/content",
        header: "Content",
    },
    Relations: {
        key: `${Math.random()}ROUTE_`,
        mainNav: false,
        path: "/relations",
        header: "Relations",
    },
    Financials: {
        key: `${Math.random()}ROUTE_`,
        mainNav: false,
        path: "/financials",
        header: "Financials",
    },
    Dashboard: {
        key: `${Math.random()}ROUTE_`,
        mainNav: false,
        path: "/dashboard",
        header: "Dashboard",
    },
    Reports: {
        key: `${Math.random()}ROUTE_`,
        mainNav: false,
        path: "/reports",
        header: "Reports",
    },
    Lists: {
        key: `${Math.random()}ROUTE_`,
        mainNav: false,
        path: "/lists",
        header: "Lists",
    },
    List: {
        key: `${Math.random()}ROUTE_`,
        mainNav: false,
        path: "/list/:listId",
        header: "List",
    },
};
export default RouteConstants;
