import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Grid, CircularProgress, Button, FormDialog, TextField, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel, Switch, DeleteHandling, ErrorHandling, useTheme, useMediaQuery, } from "../../mui";
import { Add } from "@mui/icons-material";
import { FormFieldType } from "../../../utils/enums";
import { ModelsSelect } from "../selects";
import { ContentActions, ContentTypography } from "../content";
import { v4 as uuidv4 } from "uuid";
import defaults from "../../../utils/defaults";
import { camelCaseToWords } from "../../../utils/common";
const SCHEMAS = gql `
  query SchemasDialog($organizationModelId: ID!) {
    schemas(organizationModelId: $organizationModelId) {
      id
      key
      type
      relation
      modelName
      options
      organizationModelId
    }
  }
`;
const MANAGE_SCHEMAS = gql `
  mutation ManageSchemas($data: [schemaInput]) {
    manageSchemas(data: $data) {
      id
    }
  }
`;
export const ManageDataPool = ({ schemas, editSchema, onSchemasDelete, onAddSchema }) => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.down("sm"));
    const editOption = (index = 0, option = "", schema) => {
        let options = schema.options;
        if (options.length === 0) {
            options = [option];
        }
        else {
            options[index] = option;
        }
        editSchema({
            target: {
                name: "options",
                value: options,
            },
        }, schema);
    };
    return (_jsxs(Grid, { container: true, spacing: 2, children: [schemas.length === 0 && (_jsxs(Grid, { item: true, xs: 12, children: [_jsx(ContentTypography, { field: "schemaDialogTitle" }), _jsx(ContentTypography, { field: "schemaDialogSecondaryTitle" })] })), schemas.map((schema, index) => (_jsxs(Grid, { item: true, xs: 12, children: [_jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 1, children: _jsx(Checkbox, { color: "primary", name: "selected", checked: schema.selected, onChange: (e) => editSchema(e, schema) }) }), _jsx(Grid, { item: true, xs: smMatches ? 5 : 3, children: _jsx(TextField, { name: "key", value: schema.key, label: "Key", onChange: (e) => editSchema(e, schema), size: "small", fullWidth: true }) }), _jsx(Grid, { item: true, xs: smMatches ? 5 : 3, children: _jsxs(FormControl, { style: { width: "100%" }, children: [_jsx(InputLabel, { children: _jsx(ContentTypography, { field: "typeLabel" }) }), _jsx(Select, { name: "type", value: schema.type, label: "Type", onChange: (e) => editSchema(e, schema), size: "small", children: Object.keys(FormFieldType)?.map((type, index) => (_jsx(MenuItem, { value: FormFieldType[type], children: camelCaseToWords(FormFieldType[type]) }, index))) })] }) }), _jsx(Grid, { item: true, children: _jsx(FormControlLabel, { control: _jsx(Switch, { color: "primary", checked: schema.relation, onChange: (e) => editSchema({
                                            target: {
                                                name: "relation",
                                                value: !schema.relation,
                                            },
                                        }, schema) }), label: _jsx(ContentTypography, { color: "secondary", field: "inRelationLabel" }) }) }), !schema.relation &&
                                (schema.type === FormFieldType.SingleSelect || schema.type === FormFieldType.MultiSelect) ? (_jsx(Grid, { item: true, children: _jsxs(Button, { onClick: () => editSchema({
                                        target: { name: "options", value: [...schema.options, ""] },
                                    }, schema), children: [_jsx(Add, { fontSize: "small" }), _jsx(ContentTypography, { variant: "button", color: "primary", fontWeight: "bold", sx: { textTransform: "none" }, field: "addOptionLabel" })] }) })) : null] }), _jsxs(Grid, { container: true, spacing: 1, sx: { mt: 1 }, children: [schema.relation && (_jsx(Grid, { item: true, xs: 12, children: _jsxs(Grid, { container: true, children: [_jsx(Grid, { item: true, xs: 1 }), _jsx(Grid, { item: true, xs: smMatches ? 5 : 3, children: _jsx(ModelsSelect, { fullWidth: true, name: "modelName", value: schema?.modelName, label: _jsx(ContentTypography, { field: "selectModelLabel" }), onChange: (e) => editSchema(e, schema), size: "small" }) })] }) })), !schema.relation &&
                                (schema.type === FormFieldType.SingleSelect || schema.type === FormFieldType.MultiSelect)
                                ? schema?.options?.map((option, index) => (_jsx(Grid, { item: true, xs: 12, children: _jsxs(Grid, { container: true, children: [_jsx(Grid, { item: true, xs: 1 }), _jsx(Grid, { item: true, xs: smMatches ? 5 : 3, children: _jsx(TextField, { name: "index", value: option, label: `Option ${index + 1}`, onChange: (e) => editOption(index, e.target.value, schema), size: "small" }, index) })] }) }, index)))
                                : null] })] }, index))), _jsxs(Grid, { item: true, xs: 12, children: [_jsxs(Button, { onClick: () => onAddSchema(), children: [_jsx(Add, { fontSize: "medium" }), _jsx(ContentTypography, { variant: "button", color: "primary", fontWeight: "bold", sx: { textTransform: "none" }, field: "addSchemaLabel" })] }), schemas.some((s) => s.selected) && _jsx(DeleteHandling, { onDelete: onSchemasDelete })] })] }));
};
const ManagePoolSchemasDialog = ({ open = false, onDialogChange = () => null, setSnackBar = () => null, organizationModelId = null, }) => {
    const [schemas, setSchemas] = React.useState([]);
    const [getModelSchema, { loading, error, data }] = useLazyQuery(SCHEMAS);
    const [manageSchemas] = useMutation(MANAGE_SCHEMAS);
    React.useEffect(() => {
        if (open && organizationModelId) {
            getModelSchema({
                variables: {
                    organizationModelId,
                },
            });
        }
    }, [open]);
    React.useEffect(() => {
        if (data?.schemas) {
            setSchemas(data?.schemas?.map((schema) => ({
                identifier: uuidv4(),
                id: schema.id,
                organizationModelId: schema.organizationModelId,
                key: schema.key,
                relation: schema.relation,
                modelName: schema.modelName,
                key: schema.key,
                type: schema.type,
                options: schema.options,
            } || [])));
        }
    }, [data]);
    const editSchema = (event, schema) => {
        let temp = schema;
        let newSchemas = [];
        if (event.target.name === "selected") {
            temp.selected = event.target.checked;
        }
        else {
            temp[event.target.name] = event.target.value;
        }
        let newTableData = schemas;
        if (newTableData.length === 1) {
            newSchemas = [temp];
        }
        else {
            let tableDataIndex = newTableData.indexOf(newTableData.find((tableRow) => tableRow.identifier === temp.identifier));
            newSchemas = [...newTableData.slice(0, tableDataIndex), temp, ...newTableData.slice(tableDataIndex + 1)];
        }
        setSchemas(newSchemas);
    };
    const onAddSchema = () => {
        let newSchemas = [...schemas, { identifier: uuidv4(), ...defaults.schema }];
        setSchemas(newSchemas);
    };
    const onSchemasDelete = () => setSchemas(schemas?.map((schema) => {
        if (schema?.selected) {
            return {
                ...schema,
                deleted: true,
            };
        }
        return schema;
    }));
    const onSchemaDialogSave = () => {
        const data = schemas.map((schema) => ({
            id: schema?.id || null,
            key: schema?.key || "",
            type: schema?.type || "",
            relation: schema?.relation || false,
            deleted: schema?.deleted || false,
            modelName: schema?.modelName || "",
            options: schema?.options || [],
            organizationModelId,
        }));
        manageSchemas({
            variables: {
                data,
            },
            onCompleted: () => setSnackBar({
                open: true,
                message: `Updated Model schemas successfully!!`,
                type: "success",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        onDialogChange();
    };
    if (loading)
        return (_jsx(FormDialog, { open: true, children: _jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, sx: { marginTop: "20%" }, children: _jsx(CircularProgress, { size: 60, color: "primary" }) }) }) }));
    if (error)
        return _jsx(ErrorHandling, { error: error });
    return (_jsx(FormDialog, { open: open, setOpen: onDialogChange, title: _jsx(ContentTypography, { field: "manageDataPoolProperties" }), actions: _jsx(ContentActions, { onClose: onDialogChange, onSave: onSchemaDialogSave }), children: _jsx(ManageDataPool, { schemas: schemas?.filter((schema) => !schema?.deleted) || [], editSchema: editSchema, onSchemasDelete: onSchemasDelete, onAddSchema: onAddSchema }) }));
};
export default ManagePoolSchemasDialog;
