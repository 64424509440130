import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Grid, CardContent, Card, CardMedia, CardActions, Typography, IconButton, Button, CircularProgress, ErrorHandling, useTheme, useMediaQuery, } from "../../mui";
import { Edit } from "@mui/icons-material";
import ContentTypography from "./ContentTypography";
import constants from "../../../utils/constants";
const POOL_ASSEST = gql `
  query ContentCard($poolId: ID!) {
    assets(poolId: $poolId) {
      id
      url
    }
  }
`;
const ContentCard = ({ row = null, handleRowAction = () => null }) => {
    const theme = useTheme();
    const mdMatches = useMediaQuery(theme.breakpoints.down("md"));
    const [getContentCard, { loading, error, data }] = useLazyQuery(POOL_ASSEST);
    React.useEffect(() => {
        if (row) {
            getContentCard({ variables: { poolId: row.poolId ? row?.poolId : row?.id } });
        }
    }, [row]);
    if (loading)
        return (_jsx(Card, { sx: { minWidth: 246, maxHeight: 246 }, children: _jsx(CardContent, { children: _jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, sx: { marginTop: "20%" }, children: _jsx(CircularProgress, { size: 60, color: "primary" }) }) }) }) }));
    if (error)
        return _jsx(ErrorHandling, { error: error });
    return (_jsxs(Card, { children: [_jsx(CardMedia, { sx: { minWidth: 246, maxHeight: 246 }, component: "img", loading: "lazy", image: data?.assets?.[0]?.url || "img/place_holder.png" }), _jsxs(CardContent, { children: [_jsx(Typography, { variant: "subtitle2", fontWeight: "bold", children: row?.name || "–" }), _jsx(Typography, { variant: "subtitle2", color: "text.secondary", noWrap: true, children: row?.description || "–" })] }), _jsx(CardActions, { children: _jsx(Grid, { container: true, justifyContent: "flex-end", alignItems: "flex-end", children: handleRowAction &&
                        (mdMatches ? (_jsx(Grid, { item: true, children: _jsx(IconButton, { size: "small", onClick: () => handleRowAction({
                                    open: true,
                                    id: row?.id,
                                    action: constants.OPERATION_TYPE.EDIT,
                                }), children: _jsx(Edit, { fontSize: "inherit" }) }) })) : (_jsx(Grid, { item: true, children: _jsx(Button, { size: "small", onClick: () => handleRowAction({
                                    open: true,
                                    id: row?.id,
                                    action: constants.OPERATION_TYPE.EDIT,
                                }), children: _jsx(ContentTypography, { fontWeight: "bold", sx: { textTransform: "none" }, field: "viewLabel" }) }) }))) }) })] }));
};
export default ContentCard;
