import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Grid, FormControl, InputLabel, Select, MenuItem, ErrorHandling, CircularProgress } from "../../mui";
import { ORGANIZATION_MODELS } from "../../../containers/Queries";
import { capitalizeSentence } from "../../../utils/common";
const ModelsSelect = ({ fullWidth = true, size = "medium", value = "", onChange = () => null, name = "", label = "select", }) => {
    const organizationId = localStorage.getItem("organizationId") || null;
    const { loading, error, data } = useQuery(ORGANIZATION_MODELS, {
        variables: {
            organizationId,
            modelCategory: "",
        },
    });
    if (loading)
        return (_jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, children: _jsx(CircularProgress, { size: 10, color: "primary" }) }) }));
    if (error)
        return _jsx(ErrorHandling, { error: error });
    return (_jsxs(FormControl, { style: { width: "100%" }, children: [_jsx(InputLabel, { children: "Model" }), _jsx(Select, { fullWidth: fullWidth, name: name, value: value, label: label, onChange: onChange, size: size, children: data?.organizationModels?.map((organizationModel, index) => (_jsx(MenuItem, { value: organizationModel?.model?.name || "", children: capitalizeSentence(organizationModel?.model?.name) || "" }, index))) })] }));
};
export default ModelsSelect;
