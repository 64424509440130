import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { Grid, TextField, IconButton, Button, useTheme, useMediaQuery } from "../../../mui";
import { Check, Clear } from "@mui/icons-material";
import { ContentTypography } from "../../content";
import Comment from "./Comment";
const Comments = ({ onAdd = () => null, onEdit = () => null, onDelete = () => null, data = [] }) => {
    const theme = useTheme();
    const lgMatches = useMediaQuery(theme.breakpoints.down("lg"));
    const mdMatches = useMediaQuery(theme.breakpoints.down("md"));
    const [label, setLabel] = React.useState("");
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, { container: true, direction: "row", sx: { my: mdMatches ? 0 : 1 }, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { color: "primary", variant: "subtitle2", field: "commentsLabel" }) }), _jsx(Grid, { item: true, xs: 12, sx: { m: 1 }, children: data?.map((comment, index) => (_jsx(Comment, { data: comment, onEdit: onEdit, onDelete: onDelete }, index))) }), onAdd && (_jsx(Grid, { item: true, xs: 12, sx: { mx: mdMatches ? 1 : 2, my: 1 }, children: _jsx(TextField, { fullWidth: true, value: label, onChange: (e) => setLabel(e.target.value), multiline: true, rows: 4 }) }))] }), onAdd && (_jsxs(Grid, { container: true, spacing: 2, alignItems: "flex-end", justifyContent: "flex-end", children: [_jsx(Grid, { item: true, children: lgMatches ? (_jsx(IconButton, { size: "medium", onClick: () => onAdd(label), children: _jsx(Check, { fontSize: "inherit" }) })) : (_jsxs(Button, { variant: "outlined", size: "small", onClick: () => onAdd(label), children: [_jsx(ContentTypography, { variant: "button", color: "secondary", sx: { textTransform: "none" }, field: "saveLabel" }), _jsx(Check, { fontSize: "small" })] })) }), _jsx(Grid, { item: true, sx: { mr: mdMatches ? 1 : 2 }, children: lgMatches ? (_jsx(IconButton, { onClick: () => setLabel(""), size: "medium", children: _jsx(Clear, { fontSize: "inherit" }) })) : (_jsxs(Button, { variant: "outlined", size: "small", onClick: () => setLabel(""), children: [_jsx(ContentTypography, { variant: "button", color: "secondary", sx: { textTransform: "none" }, field: "cancelLabel" }), _jsx(Clear, { fontSize: "small" })] })) })] }))] }));
};
export default Comments;
