import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { FormField, Grid, Avatar, IconButton, DeleteHandling, useTheme, useMediaQuery } from "../../../mui";
import { Check } from "@mui/icons-material";
import { FormFieldType } from "../../../../utils/enums";
import defaults from "../../../../utils/defaults";
const Comment = ({ data = null, onDelete = () => null, onEdit = () => null }) => {
    const userId = localStorage.getItem("userId") || null;
    const theme = useTheme();
    const mdMatches = useMediaQuery(theme.breakpoints.down("md"));
    const [comment, setComment] = React.useState(defaults.comments);
    const [actionVisible, setActionVisible] = React.useState(false);
    React.useEffect(() => {
        setComment(data || defaults.comments);
    }, [data]);
    return (_jsxs(Grid, { container: true, sx: { m: mdMatches ? 0 : 1 }, spacing: mdMatches ? 1 : 4, children: [_jsx(Grid, { item: true, sx: { mt: 1 }, children: _jsx(Avatar, { children: `${comment?.createdBy?.firstName?.charAt?.(0) || "–"} ${comment?.createdBy?.lastName?.charAt?.(0) || ""}` }) }), _jsx(Grid, { item: true, xs: mdMatches ? 7 : 9, children: _jsx(FormField, { fullWidth: true, multiline: true, rows: 6, disabled: comment?.createdBy?.id !== userId, size: "small", fieldType: FormFieldType.TextField, value: comment?.label, onChange: (event) => {
                        setActionVisible(true);
                        setComment({
                            ...comment,
                            label: event.target.value,
                        });
                    } }) }), comment?.createdBy?.id === userId ? (_jsxs(_Fragment, { children: [actionVisible && (_jsx(Grid, { item: true, children: _jsx(IconButton, { color: "primary", onClick: () => {
                                onEdit(comment);
                                setActionVisible(false);
                            }, size: "medium", children: _jsx(Check, { fontSize: "inherit" }) }) })), _jsx(Grid, { item: true, children: _jsx(DeleteHandling, { onDelete: () => onDelete(comment?.id), mobileOnly: true }) })] })) : null] }));
};
export default Comment;
