import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from "@apollo/client";
import { Grid, FormControl, InputLabel, Select, MenuItem, ErrorHandling, CircularProgress } from "../../mui";
const LISTS = gql `
  query ListsSelect($filters: queryFilters!) {
    lists(filters: $filters) {
      list {
        id
        label
      }
    }
  }
`;
const ListsSelect = ({ fullWidth = true, size = "medium", value = "", onChange = () => null, name = "", label = "select", }) => {
    const { loading, error, data } = useQuery(LISTS, {
        variables: {
            filters: {},
        },
    });
    if (loading)
        return (_jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, children: _jsx(CircularProgress, { size: 10, color: "primary" }) }) }));
    if (error)
        return _jsx(ErrorHandling, { error: error });
    return (_jsxs(FormControl, { style: { width: "100%" }, children: [_jsx(InputLabel, { children: "Lists" }), _jsx(Select, { fullWidth: fullWidth, name: name, value: value, label: label, onChange: onChange, size: size, children: data?.lists?.list?.map((list, index) => (_jsx(MenuItem, { value: list?.id || "", children: list?.label || "" }, index))) })] }));
};
export default ListsSelect;
