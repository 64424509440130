import { jsx as _jsx } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { useQuery } from "@apollo/client";
import { makeStyles, Grid, Paper, Tabs, Tab, ErrorHandling, useMediaQuery, useTheme } from "../mui";
import { useHistory } from "react-router-dom";
import RouteConstants from "../../routes/RouteConstants";
import { DataUsage, Analytics, Diversity2, Settings, Dashboard, ViewList, Hub, AccountBalance, } from "@mui/icons-material";
import { ORGANIZATION_PERMISSIONS } from "./Queries";
import { OrganizationPermission, OrganizationFeatures } from "../../utils/enums";
import constants from "../../utils/constants";
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: "auto",
        position: "fixed",
        bottom: 0,
        width: "100%",
    },
}));
const tabValue = (pathname) => {
    switch (pathname) {
        case RouteConstants.Dashboard.path:
            return RouteConstants.Dashboard.header;
        case RouteConstants.Content.path:
            return RouteConstants.Content.header;
        case RouteConstants.Relations.path:
            return RouteConstants.Relations.header;
        case RouteConstants.Financials.path:
            return RouteConstants.Financials.header;
        case RouteConstants.Settings.path:
            return RouteConstants.Settings.header;
        case RouteConstants.Integrations.path:
            return RouteConstants.Integrations.header;
        case RouteConstants.Reports.path:
            return RouteConstants.Reports.header;
        case RouteConstants.Lists.path:
            return RouteConstants.Lists.header;
        default:
            return RouteConstants.Settings.header;
    }
};
const NavigationFooter = (props) => {
    const id = localStorage.getItem("organizationId") || null;
    const classes = useStyles();
    const history = useHistory();
    const pathname = history.location.pathname;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("lg"));
    const [loggedOut, setLoggedOut] = React.useState(false);
    React.useEffect(() => {
        const userId = localStorage.getItem("userId");
        const organizationId = localStorage.getItem("organizationId");
        if (userId && organizationId) {
            setLoggedOut(false);
        }
        else {
            setLoggedOut(true);
        }
    }, []);
    const { loading, error, data } = useQuery(ORGANIZATION_PERMISSIONS, {
        variables: {
            id,
        },
    });
    if (loading)
        return null;
    if (error)
        return _jsx(ErrorHandling, { error: error });
    let tabs = [
        {
            label: RouteConstants.Dashboard.header,
            path: RouteConstants.Dashboard.path,
            icon: (_jsx(Dashboard, { size: 20, color: history.location.pathname === RouteConstants.Dashboard.path ? "#50E4D5" : "white" })),
        },
    ];
    const organizationPermittedFeatures = data?.organization?.permissions?.map((permission) => permission?.permission === OrganizationPermission.Visible && permission?.feature);
    const contentVisible = organizationPermittedFeatures.some((feature) => constants.MODEL_CATEGORY_MAP.content.includes(feature));
    if (contentVisible) {
        tabs.push({
            label: RouteConstants.Content.header,
            path: RouteConstants.Content.path,
            icon: (_jsx(DataUsage, { size: 20, color: history.location.pathname === RouteConstants.Content.path ? "#50E4D5" : "white" })),
        });
    }
    const relationsVisible = organizationPermittedFeatures.some((feature) => constants.MODEL_CATEGORY_MAP.relations.includes(feature));
    if (relationsVisible) {
        tabs.push({
            label: RouteConstants.Relations.header,
            path: RouteConstants.Relations.path,
            icon: (_jsx(Diversity2, { size: 20, color: history.location.pathname === RouteConstants.Relations.path ? "#50E4D5" : "white" })),
        });
    }
    const financialsVisible = organizationPermittedFeatures.some((feature) => constants.MODEL_CATEGORY_MAP.financials.includes(feature));
    if (financialsVisible) {
        tabs.push({
            label: RouteConstants.Financials.header,
            path: RouteConstants.Financials.path,
            icon: (_jsx(AccountBalance, { size: 20, color: history.location.pathname === RouteConstants.Financials.path ? "#50E4D5" : "white" })),
        });
    }
    if (organizationPermittedFeatures.includes(OrganizationFeatures.lists)) {
        tabs.push({
            label: RouteConstants.Lists.header,
            path: RouteConstants.Lists.path,
            icon: (_jsx(ViewList, { size: 20, color: history.location.pathname === RouteConstants.Lists.path ? "#50E4D5" : "white" })),
        });
    }
    if (organizationPermittedFeatures.includes(OrganizationFeatures.reports)) {
        tabs.push({
            label: RouteConstants.Reports.header,
            path: RouteConstants.Reports.path,
            icon: (_jsx(Analytics, { size: 20, color: history.location.pathname === RouteConstants.Reports.path ? "#50E4D5" : "white" })),
        });
    }
    tabs.push({
        label: RouteConstants.Integrations.header,
        path: RouteConstants.Integrations.path,
        icon: (_jsx(Hub, { size: 20, color: history.location.pathname === RouteConstants.Integrations.path ? "#50E4D5" : "white" })),
    });
    tabs.push({
        label: RouteConstants.Settings.header,
        path: RouteConstants.Settings.path,
        icon: (_jsx(Settings, { size: 20, color: history.location.pathname === RouteConstants.Settings.path ? "#50E4D5" : "white" })),
    });
    return (_jsx(Paper, { component: "footer", square: true, variant: "outlined", className: classes.paper, children: _jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: !loggedOut && matches ? (_jsx(Grid, { item: true, children: _jsx(Tabs, { value: tabValue(pathname), onChange: (event, newValue) => {
                        history.push(RouteConstants[newValue].path);
                    }, variant: "scrollable", scrollButtons: false, children: tabs.map((tab, index) => (_jsx(Tab, { value: tab.label, label: _jsx(Grid, { container: true, direciton: "row", alignItems: "center", justifyContent: "center", children: tab.icon }), sx: {
                            padding: "16px 14px",
                            minWidth: 46,
                        } }, index))) }) })) : null }) }));
};
export default NavigationFooter;
