import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, Grid, Typography, Card, CardContent, Divider, FormControlLabel, IOSSwitch, useTheme, } from "../components/mui";
import { ContentTypography } from "../components/common/content";
import AppLayout from "./common/AppLayout";
const useStyles = makeStyles((theme) => ({
    section: {
        padding: 16,
        [theme.breakpoints.down("md")]: {
            padding: 12,
        },
        [theme.breakpoints.down("sm")]: {
            padding: 8,
        },
    },
    container: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        minWidth: "348px",
        padding: "8px",
        "&:hover": {
            boxShadow: `0px 38px 38px 5px #A5A8AD`,
            cursor: "pointer",
            position: "relative",
        },
    },
    logo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "300px",
        height: "100px",
        marginBottom: "24px",
    },
    img: {
        maxWidth: "300px",
        maxHeight: "100px",
    },
    bottomContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    bottomLeftContainer: {
        display: "flex",
        flexDirection: "column",
    },
}));
const integrations = [
    {
        name: "Finances",
        list: [
            {
                name: "Stripe",
                description: "Integrate to manage payments",
                logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/320px-Stripe_Logo%2C_revised_2016.svg.png",
            },
        ],
    },
    {
        name: "Workspaces",
        list: [
            {
                name: "Google Workplace",
                description: "Integrate to manage workplace",
                logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/320px-Google_2015_logo.svg.png",
            },
            {
                name: "Slack",
                description: "Integrate to manage slack",
                logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Slack_Logo_Pre_2019.svg/320px-Slack_Logo_Pre_2019.svg.png",
            },
            {
                name: "Microsoft Teams",
                description: "Integrate to manage teams",
                logo: "https://www.silicon.fr/wp-content/uploads/2018/03/microsoft-teams.jpg",
            },
        ],
    },
    {
        name: "Miscellaneous",
        list: [
            {
                name: "Fixer io",
                description: "Enable currency conversions",
                logo: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Money-Euro-USD-LEI_53073-480x360_%284791385567%29.jpg",
            },
        ],
    },
];
const IntegrationsContainer = (props) => {
    const organizationId = localStorage.getItem("organizationId") || null;
    const classes = useStyles();
    const theme = useTheme();
    return (_jsx(AppLayout, { children: _jsxs(Grid, { container: true, spacing: 2, alignItems: "flex-start", justifyContent: "center", className: classes.section, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { align: "right", variant: "h5", color: "primary", m: 1, fontWeight: "bold", field: "integrationsLabel" }) }), _jsx(Grid, { item: true, xs: 12, sm: 12, children: _jsx(Divider, { flexItem: true }) }), _jsx(Grid, { item: true, xs: 12, children: integrations?.map((category, index) => (_jsxs(Grid, { container: true, spacing: 4, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { align: "left", variant: "h6", color: "secondary", m: 2, fontWeight: "bold", children: category?.name }) }), category?.list?.map((integration, listIndex) => (_jsx(Grid, { item: true, children: _jsx(Card, { className: classes.container, children: _jsxs(CardContent, { children: [_jsx("div", { className: classes.logo, children: _jsx("img", { src: integration?.logo, className: classes.img }) }), _jsxs("div", { className: classes.bottomContainer, children: [_jsxs("div", { className: classes.bottomLeftContainer, children: [_jsx(Typography, { variant: "paragraph_small", fontWeight: "bold", children: integration?.name }), _jsx(Typography, { variant: "paragraph_xsmall", color: theme.palette.secondary.main, children: integration?.description })] }), _jsx(FormControlLabel, { control: _jsx(IOSSwitch, { sx: { m: 1 } }) })] })] }) }) }, listIndex)))] }, index))) })] }) }));
};
export default IntegrationsContainer;
