import { gql } from "@apollo/client";
export const ORGANIZATION_PERMISSIONS = gql `
  query OrganizationPermissions($id: ID!) {
    organization(id: $id) {
      id
      permissions {
        id
        feature
        permission
      }
    }
  }
`;
