const defaults = {
    users: {
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        status: "Pending",
        userName: "",
        jobTitle: "",
        password: "",
        dateOfBirth: null,
        phoneNumber: "",
        permissions: [],
        avatar: null,
        notificationSettings: {
            dailyReports: false,
            dealsClosing: false,
            deletionAlerts: false,
            device: "Do Not Notify",
        },
        platformSettings: {
            mode: "light",
            language: "english",
            currency: "usd",
        },
    },
    clients: {
        name: "",
        description: "",
        status: "Ready",
        clientType: "Company",
        customerType: "Trial",
        industry: "",
        annualRevenue: 0,
        contactEmail: "",
        contactName: "",
        contactPhone: "",
        accountNumber: "",
        sicCode: "",
        website: "",
        source: "Advertisement",
        logoUrl: "",
        linkedinUrl: "",
        facebookUrl: "",
        twitterUrl: "",
        angelUrl: "",
        crunchbaseUrl: "",
        accountOwner: "",
        contacts: "",
        deals: "",
        invoices: "",
        engagements: "",
        tasks: "",
    },
    contacts: {
        name: "",
        description: "",
        status: "Ready",
        email: "",
        phoneNumber: "",
        role: "",
        linkedinUrl: "",
        twitterUrl: "",
        client: "",
        engagements: "",
    },
    inventories: {
        name: "",
        description: "",
        status: "Ready",
        amount: 0,
        quantity: 0,
    },
    organizations: {
        name: "",
        website: "",
        industry: "",
        linkedinUrl: "",
        facebookUrl: "",
        twitterUrl: "",
        angelUrl: "",
        crunchbaseUrl: "",
    },
    activties: {
        label: "",
        value: "",
        modelName: "",
        path: "",
    },
    leads: {
        name: "",
        description: "",
        status: "Ready",
        leadType: "AttemtpedToContact",
        source: "Advertisement",
        amount: 0,
        projectedClosingDate: null,
        expectedRevenue: 0,
        probability: 0,
        nextStep: "",
        accountOwner: "",
        client: "",
        campaign: "",
    },
    deals: {
        name: "",
        description: "",
        status: "Ready",
        dealType: "New",
        source: "Advertisement",
        amount: 0,
        closingDate: null,
        expectedRevenue: 0,
        probability: 0,
        nextStep: "",
        accountOwner: "",
        client: "",
        campaign: "",
    },
    campaigns: {
        name: "",
        description: "",
        status: "Ready",
        priority: "Medium",
        startDate: null,
        endDate: null,
        campaignType: "Email",
        budgetedCost: 0,
        expectedRevenue: 0,
        actualCost: 0,
        numbersSent: 0,
        expectedResponse: "",
        accountOwner: "",
        leads: "",
        deals: "",
    },
    invoices: {
        name: "",
        description: "",
        status: "Ready",
        amount: 0,
        dueDate: null,
        client: "",
        accountOwner: "",
        deal: "",
    },
    engagements: {
        name: "",
        description: "",
        status: "Ready",
        engagementDate: null,
        client: "",
        contact: "",
    },
    pools: {
        name: "",
        description: "",
        status: "Ready",
    },
    products: {
        name: "",
        description: "",
        status: "Ready",
        client: "",
        inventory: "",
        accountOwner: "",
        price: {
            unitAmount: 0,
            type: "",
            taxBehavior: "",
            currency: "",
            billingScheme: "",
            recurring: {
                intervalCount: 0,
                interval: "",
                aggregateUsage: "",
                trialPeriodDays: 0,
            },
        },
    },
    subscriptions: {
        name: "",
        description: "",
        status: "Ready",
        customerType: "Trial",
        startDate: null,
        endDate: null,
        pauseDate: null,
        pauseCollection: {
            behavior: "",
        },
        cancellationDetails: {
            comment: "",
            feedback: "",
        },
        client: "",
        deal: "",
        accountOwner: "",
    },
    charges: {
        name: "",
        description: "",
        status: "Ready",
        client: "",
        subscription: "",
        invoice: "",
    },
    reports: {
        label: "",
        star: false,
        chartType: "line",
        frequency: "Annually",
        modelName: "",
        fieldName: "",
        operation: "Count",
        calculation: "",
        calculationValue: "",
        xAxis: "",
        yAxis: "",
    },
    lists: {
        label: "",
        star: false,
        dynamic: false,
        modelName: "",
        fieldName: "",
        calculation: "",
        calculationValue: "",
    },
    statistics: {
        label: "",
        star: false,
        modelName: "",
        fieldName: "",
        operation: "Count",
        calculation: "",
        calculationValue: "",
    },
    address: {
        streetNumber: "",
        streetName: "",
        city: "",
        state: "",
        country: "",
        countryCode: "",
        postalCode: "",
        latitude: 37.7749,
        longitude: -122.4194,
    },
    schema: {
        deleted: false,
        selected: false,
        key: "",
        type: "",
        options: [],
    },
    comments: {
        label: "",
    },
    tasks: {
        name: "",
        description: "",
        status: "Ready",
        priority: "Medium",
        dueDate: null,
        reminder: false,
        reminderDate: null,
        reminderTime: "",
        repeat: false,
        repeatDate: null,
        repeatTime: "",
        client: "",
        assignee: "",
    },
};
export default defaults;
