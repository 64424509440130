const constants = {
    APP_LANGUAGES: {
        English: "English",
    },
    PAGE_LIMIT: "4",
    ROWS_PER_PAGE: ["4", "8", "16", "32", "48"],
    DEFAULT_FREQUENCY: "5",
    DEFAULT_ORDER: "asc",
    DEFAULT_SORT: "createdAt",
    DEFAULT_KANBAN_FIELD: "status",
    DEFAULT_PRIMARY_THEME: "#00a893",
    GQL_FIELD_TYPE: {
        LIST: "LIST",
        OBJECT: "OBJECT",
        Enum: "Enum",
        String: "String",
        Float: "Float",
        Int: "Int",
        Boolean: "Boolean",
    },
    STATIC_STORAGE_BUCKET_FOLDERS: {
        avatars: "avatars",
        exports: "exports",
        imports: "imports",
        logos: "logos",
        pools: "pools",
    },
    OPERATION_TYPE: {
        ADD: "Add",
        EDIT: "Edit",
    },
    CONTENTS_CUSTOM_TYPES: {
        accountOwners: "accountOwners",
        assignees: "assignees",
    },
    MODEL_CATEGORY_MAP: {
        leads: "relations",
        campaigns: "relations",
        tasks: "content",
        clients: "content",
        inventories: "content",
        deals: "relations",
        invoices: "content",
        contacts: "relations",
        engagements: "relations",
        products: "financials",
        subscriptions: "financials",
        charges: "financials",
        content: ["clients", "inventories", "invoices", "tasks"],
        relations: ["deals", "contacts", "engagements", "leads", "campaigns"],
        financials: ["products", "subscriptions", "charges"],
    },
    SETTING_TABS: {
        Personal: "Personal",
        Notifications: "Notifications",
        Organization: "Organization",
        Features: "Features",
        Team: "Team",
    },
    PARENT_ORGANIZATION_TYPE: {
        parent: "parent",
        sub: "sub",
    },
    POPULAR_EMAILS: [
        "gmail",
        "yahoo",
        "aol",
        "outlook",
        "aim",
        "yandex",
        "protonmail",
        "zoho",
        "gmx",
        "tutanota",
        "tutamail",
        "tuta",
        "keemail",
        "icloud",
    ],
    STATES: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
    ],
    COUNTRIES: ["USA"],
    DEFAULT_INTEGRATIONS: [
        {
            name: "Finances",
            list: [
                {
                    name: "Stripe",
                    description: "Stripe's payments platform lets you accept credit cards, debit cards, and popular payment methods around the world—all with a single integration. Get access to advanced payments features like 3D Secure 2 authentication, card updates, automated retries, and more.",
                    title: "Integrate to stripe service and manage financial models",
                    logo: "img/integrations/stripe.png",
                    new: false,
                },
            ],
        },
        {
            name: "Workspaces",
            list: [
                {
                    name: "Google Workplace",
                    description: "Google Workspace (formerly G Suite) is a cloud-based productivity suite that provides tools for collaboration, communication, and business operations. Many companies use Google Workspace to improve efficiency, collaboration, and streamline workflows.",
                    title: "Integrate to Workspace and manage team members",
                    logo: "img/integrations/google_workspace.webp",
                    new: true,
                },
                {
                    name: "Microsoft Teams",
                    description: "Microsoft Teams is a collaboration and communication platform designed to facilitate teamwork in organizations, particularly those that rely on Microsoft’s ecosystem. It integrates chat, video conferencing, file storage, and app integration, making it a hub for team collaboration.",
                    title: "Integrate with your organization microsoft teams to manage employees",
                    logo: "img/integrations/microsoft_teams.png",
                    new: true,
                },
                {
                    name: "Slack",
                    description: "Slack is a cloud-based messaging platform designed to enhance team communication and collaboration. It acts as a hub where teams can communicate in real-time, share files, and integrate with other software tools, streamlining workflows and reducing the need for long email threads.",
                    title: "Integrate with your organization slack and manage notifications",
                    logo: "img/integrations/slack.png",
                    new: true,
                },
            ],
        },
        {
            name: "Miscellaneous",
            list: [
                {
                    name: "Fixer io",
                    description: "Fixer.io is an API service that provides real-time and historical exchange rate data for foreign currencies. It is primarily used by developers, businesses, and financial institutions to integrate currency conversion and exchange rate information into their applications, websites, or services.",
                    title: "Integration with fixer io to enable currency conversions across the platform",
                    logo: "img/integrations/fixer.png",
                    new: true,
                },
            ],
        },
    ],
};
export default constants;
