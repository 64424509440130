import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { ContentTypography } from "../common/content";
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
const InputFileUpload = ({ name = "", onChange = () => null }) => (_jsxs(Button, { role: undefined, component: "label", size: "small", variant: "contained", tabIndex: -1, startIcon: _jsx(CloudUpload, { fontSize: "inherit" }), children: [_jsx(ContentTypography, { variant: "subtitle2", fontWeight: "bold", sx: { textTransform: "none" }, field: "uploadFileLabel" }), _jsx(VisuallyHiddenInput, { type: "file", name: name, onChange: onChange })] }));
export default InputFileUpload;
