import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//@ts-check
import React from "react";
import { Grid, IconButton, Paper, Typography, TablePagination, Tooltip, Button, useTheme, useMediaQuery, } from "../../mui";
import { Edit } from "@mui/icons-material";
import { getContent } from "./ContentTypography";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { StatusType, FormFieldOrder } from "../../../utils/enums";
import constants from "../../../utils/constants";
const ContentKanban = ({ rows = [], handleRowAction = () => null, onSubmitCheck = () => null, refetch = () => null, order = FormFieldOrder.asc, orderBy = constants.DEFAULT_SORT, count = 0, page = 0, rowsPerPage = 0, rowsPerPageOptions = constants.ROWS_PER_PAGE.map((pages) => parseInt(pages)), pagination = true, fieldName = constants.DEFAULT_KANBAN_FIELD, options = Object.keys(StatusType), isDragDisabled = false, }) => {
    const theme = useTheme();
    const mdMatches = useMediaQuery(theme.breakpoints.down("md"));
    const [kanban, setKanban] = React.useState([]);
    React.useEffect(() => {
        if (rows.length > 0 && options.length > 0) {
            const result = options?.map((fieldType) => ({
                label: fieldType,
                value: rows?.filter((row) => row?.[fieldName] === fieldType),
            }));
            setKanban(result);
        }
    }, [rows]);
    const grid = 8;
    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        minWidth: grid * 16,
        maxWidth: grid * 36,
        cursor: "pointer",
        ...draggableStyle,
    });
    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? "lightblue" : "transparent",
        padding: grid,
        minWidth: grid * 16,
        maxWidth: grid * 36,
    });
    const onDragEnd = (result) => {
        if (!result?.destination) {
            return;
        }
        const item = kanban?.find((k) => k?.label === result?.source?.droppableId)?.value[result?.source?.index];
        const status = result?.destination?.droppableId;
        onSubmitCheck({
            id: item?.id,
            name: item?.name,
            status,
        });
    };
    return (_jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx("div", { style: { display: "flex", width: "100%" }, children: _jsx(DragDropContext, { onDragEnd: onDragEnd, children: kanban?.map((column, index) => (_jsx(Droppable, { droppableId: column?.label, children: (provided, snapshot) => (_jsx("div", { ref: provided?.innerRef, style: getListStyle(snapshot?.isDraggingOver), ...provided?.droppableProps, children: _jsxs(Grid, { container: true, spacing: 4, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "subtitle1", style: {
                                                    display: "flex",
                                                    justifyContent: "space-around",
                                                    fontWeight: "bold",
                                                }, color: "primary", children: column?.label || "–" }) }), _jsxs(Grid, { item: true, xs: 12, children: [column?.value?.map((item, columnIndex) => (_jsx(Draggable, { draggableId: item?.id, index: columnIndex, isDragDisabled: isDragDisabled, children: (provided, snapshot) => (_jsx(Paper, { elevation: 6, ref: provided?.innerRef, ...provided?.draggableProps, ...provided?.dragHandleProps, style: getItemStyle(snapshot?.isDragging, provided?.draggableProps?.style), children: _jsx(Tooltip, { title: getContent("dragNDropMessage"), children: _jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "subtitle2", fontWeight: "bold", children: item?.name || "–" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "subtitle2", color: "text.secondary", fontWeight: "semi-bold", noWrap: true, children: item?.description || "–" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Grid, { container: true, justifyContent: "flex-end", alignContent: "flex-end", children: handleRowAction &&
                                                                                (mdMatches ? (_jsx(Grid, { item: true, children: _jsx(IconButton, { size: "small", onClick: () => handleRowAction({
                                                                                            open: true,
                                                                                            id: item?.id || null,
                                                                                            action: constants.OPERATION_TYPE.EDIT,
                                                                                        }), children: _jsx(Edit, { fontSize: "inherit" }) }) })) : (_jsx(Grid, { item: true, children: _jsx(Button, { size: "small", onClick: () => handleRowAction({
                                                                                            open: true,
                                                                                            id: item?.id || null,
                                                                                            action: constants.OPERATION_TYPE.EDIT,
                                                                                        }), children: "View" }) }))) }) })] }) }) })) }, columnIndex))), provided?.placeholder] })] }) })) }, index))) }) }) }), _jsx(Grid, { item: true, xs: 12, children: pagination ? (_jsx(TablePagination, { component: "div", rowsPerPageOptions: rowsPerPageOptions || constants.ROWS_PER_PAGE.map((pages) => parseInt(pages)), count: count || 0, rowsPerPage: rowsPerPage || parseInt(constants.PAGE_LIMIT), page: page || 0, onPageChange: (event, newPage) => {
                        refetch({
                            filters: { newPage, rowsPerPage, order, orderBy },
                        });
                    }, onRowsPerPageChange: (event) => {
                        refetch({
                            filters: { newPage: 0, rowsPerPage: parseInt(event.target.value, 10), order, orderBy },
                        });
                    } })) : null })] }));
};
export default ContentKanban;
