import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ContentTypography } from "../common/content";
import { signOut } from "../../utils/auth/graph_helper";
import RouteConstants from "../../routes/RouteConstants";
const ErrorHandling = ({ open = true, error = "" }) => {
    const history = useHistory();
    return (_jsxs(Dialog, { open: open, children: [_jsx(DialogTitle, { children: _jsx(ContentTypography, { color: "error", fontWeight: "bold", field: "errorLabel" }) }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { children: _jsx(ContentTypography, { field: "errorHandlingMessage" }) }), _jsxs(Typography, { color: "error", variant: "subtitl2", fontWeight: "bold", children: [_jsx("br", {}), error?.message || "–"] })] }), _jsx(DialogActions, { children: _jsx(Button, { onClick: async () => {
                        await signOut();
                        history.push(RouteConstants.Login.path);
                    }, autoFocus: true, children: _jsx(ContentTypography, { field: "okayLabel", variant: "button", color: "primary", fontWeight: "bold", sx: { textTransform: "none" } }) }) })] }));
};
export default ErrorHandling;
