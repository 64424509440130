import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, Grid, ImageList, ImageListItem, CardMedia, InputFileUpload, DeleteHandling, } from "../../mui";
import { ContentTypography } from "../content";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "scroll",
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        flexWrap: "nowrap",
        transform: "translateZ(0)",
    },
}));
const Assets = ({ onSave = () => null, onDelete = () => null, data = [] }) => {
    const classes = useStyles();
    return (_jsxs(Grid, { container: true, spacing: 4, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { color: "primary", variant: "subtitle2", field: "assetsLabel", children: data.length > 0 ? data.length : "–" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx("div", { className: classes.root, children: _jsxs(ImageList, { className: classes.imageList, cols: data?.length + 1, children: [onSave && (_jsx(ImageListItem, { sx: { mt: 4 }, children: _jsx(InputFileUpload, { onChange: onSave }) })), data?.map((asset, index) => (_jsxs(ImageListItem, { children: [_jsx(CardMedia, { alt: "asset", image: asset.url, sx: { width: 124, height: 124 }, loading: "lazy", component: "img" }), _jsx(DeleteHandling, { variant: "filled", style: { position: "absolute", left: "68%", top: "-4%" }, mobileOnly: true, onDelete: () => onDelete(asset?.id) })] }, index)))] }) }) })] }));
};
export default Assets;
