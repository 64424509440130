import { gql } from "@apollo/client";
export const ORGANIZATION_MODELS = gql `
  query OrganizationModels($organizationId: ID!, $modelCategory: String) {
    organizationModels(modelCategory: $modelCategory) {
      id
      organizationId
      model {
        id
        name
      }
    }
    organization(id: $organizationId) {
      id
      primaryTheme
      permissions {
        id
        feature
        permission
      }
    }
    currentUser {
      id
      role
      platformSettings {
        mode
      }
      permissions {
        id
        feature
        permission
      }
    }
  }
`;
