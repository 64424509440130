import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, InputFileUpload, } from "../../mui";
import { ContentTypography } from "../content";
const FormUpload = ({ title = "", description = "", handleSubmit = () => null, childComponent, children }) => {
    const [open, setOpen] = React.useState(false);
    const [uploadAsset, setUploadAsset] = React.useState(null);
    return (_jsxs(React.Fragment, { children: [React.cloneElement(children, { onClick: () => setOpen(true) }), _jsxs(Dialog, { open: open, onClose: () => {
                    setOpen(false);
                }, children: [_jsx(DialogTitle, { children: title }), _jsxs(DialogContent, { children: [_jsxs(Grid, { container: true, spacing: 2, direction: "row", children: [_jsx(Grid, { item: true, children: _jsx(DialogContentText, { children: description }) }), _jsx(Grid, { item: true, children: _jsx(InputFileUpload, { onChange: (event) => setUploadAsset(event?.target?.files[0]) }) })] }), childComponent] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: () => {
                                    handleSubmit({ uploadAsset });
                                    setOpen(false);
                                }, autoFocus: true, children: _jsx(ContentTypography, { variant: "button", color: "primary", fontWeight: "bold", sx: { textTransform: "none" }, field: "submitLabel" }) }), _jsx(Button, { onClick: () => {
                                    setOpen(false);
                                }, children: _jsx(ContentTypography, { variant: "button", color: "primary", fontWeight: "bold", sx: { textTransform: "none" }, field: "cancelLabel" }) })] })] })] }));
};
export default FormUpload;
