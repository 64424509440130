import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Grid, FormLabel, FormControl, Typography, InputLabel, Select, MenuItem, IconButton, makeStyles, ErrorHandling, CircularProgress, } from "../../mui";
import { Clear } from "@mui/icons-material";
import { FormFieldType } from "../../../utils/enums";
const CONTENTS = gql `
  query ContentsSelect($modelName: String) {
    contents(modelName: $modelName) {
      label
      value
    }
  }
`;
const useStyles = makeStyles((theme) => ({
    defaultMode: {
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 8,
        paddingRight: 8,
        cursor: "pointer",
        "&:hover": {
            borderRadius: 10,
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        },
    },
    formLabel: {
        cursor: "pointer",
    },
    formValue: {
        cursor: "pointer",
    },
}));
const ContentsSelect = ({ fullWidth = true, editMode = false, disabled = false, size = "medium", value = "", onChange = () => null, setEditMode = () => null, name = "", modelName = "", fieldType = "", label = "select", }) => {
    const classes = useStyles();
    const [getContents, { loading, error, data }] = useLazyQuery(CONTENTS);
    React.useEffect(() => getContents({
        variables: {
            modelName,
        },
    }), [modelName]);
    if (loading)
        return (_jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, children: _jsx(CircularProgress, { size: 10, color: "primary" }) }) }));
    if (error)
        return _jsx(ErrorHandling, { error: error });
    if (editMode) {
        return (_jsxs(Grid, { container: true, direction: "row", children: [_jsx(Grid, { item: true, xs: 11, children: _jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { children: label }), _jsx(Select, { multiple: fieldType === FormFieldType.MultiSelect, fullWidth: fullWidth, name: name, value: fieldType === FormFieldType.MultiSelect ? value?.split?.(", ") || [] : value, label: label, onChange: (event) => {
                                    let { target: { name, value }, } = event;
                                    if (fieldType === FormFieldType.MultiSelect) {
                                        value = value.filter((temp) => {
                                            return /\S/.test(temp);
                                        });
                                        value = value.join(", ");
                                    }
                                    onChange?.({
                                        target: {
                                            name,
                                            value,
                                        },
                                    });
                                }, size: size, onBlur: () => setEditMode?.(false), onClose: () => setEditMode?.(false), children: data?.contents?.map((content, index) => (_jsx(MenuItem, { value: content?.value || "", children: content?.label || "" }, index))) })] }) }), _jsx(Grid, { item: true, children: _jsx(IconButton, { size: "small", color: "primary", onClick: () => onChange({
                            target: {
                                name,
                                value: "",
                            },
                        }), children: _jsx(Clear, { fontSize: "medium" }) }) })] }));
    }
    return (_jsxs("div", { onClick: () => {
            if (!disabled) {
                setEditMode?.(true);
            }
        }, className: classes.defaultMode, children: [_jsx(FormLabel, { className: classes.formLabel, children: label }), _jsx(Typography, { className: classes.formValue, children: fieldType === FormFieldType.MultiSelect
                    ? data?.contents
                        ?.map((content) => (value?.split?.(", ")?.includes?.(content?.value) ? content.label : null))
                        ?.filter((content) => content)
                        ?.join(" | ")
                    : data?.contents?.find((content) => content?.value === value)?.label })] }));
};
export default ContentsSelect;
