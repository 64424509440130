import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import { Divider, Grid, Typography, Tooltip, FormTable, makeStyles, Loader, ErrorHandling, IconButton, Button, } from "../components/mui";
import { Add, Edit, NavigateBefore, Refresh } from "@mui/icons-material";
import { UserPermission, FeatureType } from "../utils/enums";
import { ContentToCSV, ContentToPDF, ContentTypography, getContent } from "../components/common/content";
import AppLayout from "./common/AppLayout";
import ManageList from "../components/Lists/ManageList";
import constants from "../utils/constants";
import RouteConstants from "../routes/RouteConstants";
import ColorModeContext from "../routes/ColorModeContext";
const LIST = gql `
  query List($id: ID!) {
    __type(name: "list") {
      name
      fields {
        name
        type {
          name
          kind
          enumValues {
            name
          }
        }
      }
    }
    list(id: $id) {
      id
      createdAt
      label
      star
      pools {
        id
        createdAt
        name
        description
        editable
      }
    }
    currentUser {
      id
      role
      organization {
        id
        primaryTheme
      }
      platformSettings {
        mode
      }
      permissions {
        id
        feature
        permission
      }
    }
  }
`;
export const MANAGE_LIST_POOLS = gql `
  mutation ManageListPools($listId: ID!, $data: [poolInput]) {
    manageListPools(listId: $listId, data: $data) {
      id
    }
  }
`;
const useStyles = makeStyles((theme) => ({
    section: {
        padding: 16,
        [theme.breakpoints.down("md")]: {
            padding: 12,
        },
        [theme.breakpoints.down("sm")]: {
            padding: 8,
        },
    },
}));
const ListContainer = (props) => {
    const organizationId = localStorage.getItem("organizationId") || null;
    const classes = useStyles();
    const history = useHistory();
    const params = useParams();
    const colorMode = React.useContext(ColorModeContext);
    const [actionDialogState, setActionDialogState] = React.useState({
        open: false,
        id: null,
        action: null,
    });
    const [pools, setPools] = React.useState([]);
    const { loading, error, data, refetch } = useQuery(LIST, {
        variables: {
            id: params?.listId || null,
        },
    });
    const [manageListPools] = useMutation(MANAGE_LIST_POOLS, {
        refetchQueries: ["List"],
    });
    React.useEffect(() => {
        if (data) {
            setPools(data?.list?.pools?.map((pool) => ({ ...pool, selected: false })) || []);
            colorMode.defaultMode({
                mode: data?.currentUser?.platformSettings?.mode,
            });
            colorMode.togglePrimaryTheme({
                color: data?.currentUser?.organization?.primaryTheme || constants.DEFAULT_PRIMARY_THEME,
            });
        }
    }, [data]);
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const editRow = (event, pool) => {
        let temp = pool;
        if (event.target.name === "selected") {
            temp.selected = event.target.checked;
        }
        else {
            temp[event.target.name] = event.target.value;
        }
        let newTableData = pools;
        if (newTableData.length === 1) {
            setPools([temp]);
        }
        else {
            let tableDataIndex = newTableData.indexOf(newTableData.find((tableRow) => tableRow.id === temp.id));
            const editedTableData = [
                ...newTableData.slice(0, tableDataIndex),
                temp,
                ...newTableData.slice(tableDataIndex + 1),
            ];
            setPools((prev) => [...editedTableData]);
        }
    };
    const manageContent = (pool) => {
        manageListPools({
            variables: {
                listId: params?.listId,
                data: [
                    {
                        id: pool?.id || null,
                        name: pool?.name || "",
                        description: pool?.description || "",
                        editable: pool?.editable || true,
                    },
                ],
            },
        });
    };
    const onListDelete = () => {
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
        history.push(RouteConstants.Lists.path);
    };
    const manageActionPermitted = data?.currentUser?.permissions?.find((permission) => permission?.feature === FeatureType.ListsManage)
        ?.permission === UserPermission.Manage;
    const downloadActionPermitted = data?.currentUser?.permissions?.find((permission) => permission?.feature === FeatureType.DownloadContent)
        ?.permission === UserPermission.Manage;
    return (_jsxs(AppLayout, { children: [_jsx(ManageList, { organizationId: organizationId, actionDialogState: actionDialogState, setActionDialogState: setActionDialogState, onDelete: onListDelete }), _jsxs(Grid, { container: true, alignItems: "center", justifyContent: "center", className: classes.section, children: [_jsx(Grid, { item: true, xs: 12, children: _jsxs(Grid, { container: true, justifyContent: "space-between", alignItems: "center", children: [_jsx(Grid, { item: true, children: _jsx(Tooltip, { title: getContent("navigateBackToLists"), children: _jsxs(Button, { onClick: () => history.push(RouteConstants.Lists.path), color: "primary", children: [_jsx(NavigateBefore, { fontSize: "large" }), _jsxs(Typography, { variant: "h6", sx: { textTransform: "none" }, children: [data?.list?.label, " (", data?.list?.pools?.length, ")"] })] }) }) }), manageActionPermitted ? (_jsxs(Grid, { item: true, children: [_jsx(IconButton, { onClick: () => refetch(), color: "primary", children: _jsx(Refresh, { fontSize: "small" }) }), _jsx(IconButton, { onClick: () => setActionDialogState({
                                                open: true,
                                                id: data?.list?.id || null,
                                                action: constants.OPERATION_TYPE.EDIT,
                                            }), children: _jsx(Edit, { fontSize: "small" }) })] })) : null] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Divider, { flexItem: true }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Grid, { container: true, spacing: 2, children: pools?.length === 0 ? (_jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { color: "secondar", m: 4, align: "center", field: "noDataDescription" }) })) : null }) }), pools?.length > 0 ? (_jsx(Grid, { item: true, xs: 12, my: 1, children: _jsx(ContentToCSV, { disabled: !downloadActionPermitted, filename: `${data?.list?.label}.csv`, data: pools }) })) : null, pools?.length > 0 ? (_jsx(ContentToPDF, { disabled: !downloadActionPermitted, filename: `${data?.list?.label}.pdf`, children: _jsx(Grid, { item: true, xs: 12, mt: 2, children: _jsx(FormTable, { headers: [
                                    {
                                        label: "Created Date",
                                        key: "createdAt",
                                        component: "th",
                                        scope: "row",
                                        align: "left",
                                        numeric: false,
                                        width: "16%",
                                    },
                                    {
                                        label: "Name",
                                        key: "name",
                                        component: null,
                                        scope: null,
                                        align: "left",
                                        numeric: false,
                                        width: "40%",
                                    },
                                    {
                                        label: "Description",
                                        key: "description",
                                        component: null,
                                        scope: null,
                                        align: "left",
                                        numeric: false,
                                        width: "48%",
                                    },
                                ], rows: pools || [], fields: [], notIncludedFields: ["createdAt"], editRow: manageActionPermitted ? editRow : null, handleSelectAllVisible: false, handleSelectAllClick: false, handleRowVisible: false, count: pools?.length || 0, navigateRowVisible: false, pagination: false, onSubmitCheck: manageContent, fields: data?.__type?.fields }) }) })) : null, manageActionPermitted && (_jsx(Grid, { item: true, xs: 12, sx: { mt: 4 }, children: _jsx(Tooltip, { title: getContent("addListMessage"), children: _jsxs(Button, { fullWidth: true, color: "primary", variant: "outlined", onClick: () => setPools([
                                    ...pools,
                                    {
                                        createdAt: new Date().toDateString(),
                                        name: "",
                                        description: "",
                                        editable: true,
                                    },
                                ]), children: [_jsx(ContentTypography, { variant: "button", color: "primary", sx: { textTransform: "none" }, field: "addPoolLabel", fontWeight: "bold" }), _jsx(Add, { fontSize: "small" })] }) }) }))] })] }));
};
export default ListContainer;
