import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { Grid, FormDialog, DialogContent, DialogContentText, Button, IconButton, Typography, useTheme, useMediaQuery, } from "../../../mui";
import { Pause, ArrowRight } from "@mui/icons-material";
import { ContentActions, ContentTypography } from "../../content";
const PAUSE_SUBSCRIPTION = gql `
  mutation PauseSubscription($subscriptionId: ID!) {
    pauseSubscription(subscriptionId: $subscriptionId) {
      id
    }
  }
`;
const RESUME_SUBSCRIPTION = gql `
  mutation ResumeSubscription($subscriptionId: ID!) {
    resumeSubscription(subscriptionId: $subscriptionId) {
      id
    }
  }
`;
const PauseSubscriptionDialog = ({ data = null }) => {
    const theme = useTheme();
    const lgMatches = useMediaQuery(theme.breakpoints.down("lg"));
    const [open, setOpen] = React.useState(false);
    const [pauseSubscription] = useMutation(PAUSE_SUBSCRIPTION);
    const [resumeSubscription] = useMutation(RESUME_SUBSCRIPTION);
    return (_jsxs(React.Fragment, { children: [lgMatches ? (_jsx(IconButton, { onClick: () => setOpen(!open), size: "medium", color: "primary", children: data?.subscriptionEndDate ? _jsx(ArrowRight, { fontSize: "inherit" }) : _jsx(Pause, { fontSize: "inherit" }) })) : (_jsxs(Button, { size: "small", color: "primary", variant: "outlined", onClick: () => setOpen(!open), children: [_jsx(Typography, { variant: "button", color: "secondary", sx: { textTransform: "none" }, children: data?.subscriptionEndDate ? "Resume" : "Pause" }), data?.subscriptionEndDate ? _jsx(ArrowRight, { fontSize: "small" }) : _jsx(Pause, { fontSize: "small" })] })), _jsx(FormDialog, { open: open, setOpen: setOpen, title: _jsx(ContentTypography, { field: "pauseSubscription" }), actions: _jsx(ContentActions, { onClose: () => setOpen(!open), onSave: () => {
                        if (data?.subscriptionEndDate) {
                            resumeSubscription({
                                variables: {
                                    subscriptionId: data?.id,
                                },
                            });
                        }
                        else {
                            pauseSubscription({
                                variables: {
                                    subscriptionId: data?.id,
                                },
                            });
                        }
                        setOpen(false);
                    } }), children: _jsx(DialogContent, { children: _jsxs(Grid, { container: true, spacing: 4, children: [_jsx(Grid, { item: true, children: _jsx(DialogContentText, { children: _jsx(ContentTypography, { field: "pauseSubscriptionTitle" }) }) }), _jsx(Grid, { item: true, children: _jsx(DialogContentText, { children: _jsx(ContentTypography, { field: "pauseSubscriptionDescription" }) }) }), _jsx(Grid, { item: true, children: _jsx(DialogContentText, { children: _jsx(ContentTypography, { field: "thankYouMessage" }) }) })] }) }) })] }));
};
export default PauseSubscriptionDialog;
