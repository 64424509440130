import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Grid, useTheme, useMediaQuery, TextField, Button, DeleteHandling, Loader, ErrorHandling } from "../mui";
import { ContentTypography } from "../common/content";
import pluralize from "pluralize";
const MANAGE_ORGANIZATION_MODEL = gql `
  mutation ManageOrganizationModel($data: organizationModelInput!) {
    manageOrganizationModel(data: $data) {
      id
    }
  }
`;
const ManageModel = ({ category = "", modelName = "", setSnackBar = () => null }) => {
    const history = useHistory();
    const theme = useTheme();
    const mdMatches = useMediaQuery(theme.breakpoints.down("md"));
    const organizationId = localStorage.getItem("organizationId") || null;
    const [name, setName] = React.useState(modelName);
    const [manageOrganizationModel, { loading, data, error }] = useMutation(MANAGE_ORGANIZATION_MODEL);
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(ErrorHandling, { error: error });
    return (_jsxs(Grid, { container: true, spacing: 2, sx: { m: mdMatches ? 1 : 2 }, children: [_jsx(Grid, { item: true, sx: { mt: 1 }, children: _jsx(ContentTypography, { variant: "subtitle2", field: "dataModelNameLabel" }) }), _jsx(Grid, { item: true, children: _jsx(TextField, { size: "small", name: "name", value: name, onChange: (e) => setName(e.target.value) }) }), _jsx(Grid, { item: true, children: _jsx(Button, { size: "medium", variant: "contained", disabled: pluralize?.(name)?.toLowerCase() === pluralize?.(modelName)?.toLowerCase(), onClick: () => manageOrganizationModel({
                        variables: {
                            data: {
                                organizationId,
                                model: {
                                    name: pluralize?.(name)?.toLowerCase(),
                                    oldName: pluralize?.(modelName)?.toLowerCase(),
                                    category,
                                },
                            },
                        },
                        onCompleted: () => {
                            setSnackBar({
                                open: true,
                                message: `Updated Model settings successfully!!`,
                                type: "success",
                            });
                            history.go(0);
                        },
                        onError: () => setSnackBar({
                            open: true,
                            message: `${getContent("errorMessage")}`,
                            type: "error",
                        }),
                    }), children: _jsx(ContentTypography, { variant: "button", sx: { textTransform: "none" }, field: "saveLabel" }) }) }), modelName !== "" && (_jsx(Grid, { item: true, children: _jsx(DeleteHandling, { onDelete: () => manageOrganizationModel({
                        variables: {
                            data: {
                                organizationId,
                                model: { oldName: pluralize?.(modelName)?.toLowerCase() },
                                delete: true,
                            },
                        },
                        onCompleted: () => {
                            setSnackBar({
                                open: true,
                                message: `Updated Model settings successfully!!`,
                                type: "info",
                            });
                            history.go({
                                search: "",
                            });
                        },
                        onError: () => setSnackBar({
                            open: true,
                            message: `${getContent("errorMessage")}`,
                            type: "error",
                        }),
                    }) }) }))] }));
};
export default ManageModel;
