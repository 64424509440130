import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from "@apollo/client";
import { Grid, FormControl, InputLabel, Select, MenuItem, ErrorHandling, CircularProgress } from "../../mui";
import { camelCaseToWords } from "../../../utils/common";
const FIELDS = gql `
  query FieldsSelect($modelName: String) {
    fields(modelName: $modelName)
  }
`;
const FieldsSelect = ({ fullWidth = true, size = "medium", value = "", onChange = () => null, name = "fieldName", modelName = "", label = "select", }) => {
    const { loading, error, data } = useQuery(FIELDS, {
        variables: {
            modelName,
        },
    });
    if (loading)
        return (_jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, children: _jsx(CircularProgress, { size: 10, color: "primary" }) }) }));
    if (error)
        return _jsx(ErrorHandling, { error: error });
    return (_jsxs(FormControl, { style: { width: "100%" }, children: [_jsx(InputLabel, { children: "Fields" }), _jsx(Select, { fullWidth: fullWidth, name: name, value: value, label: label, onChange: onChange, size: size, children: data?.fields?.map((field, index) => (_jsx(MenuItem, { value: field || "", children: camelCaseToWords(field) || "" }, index))) })] }));
};
export default FieldsSelect;
