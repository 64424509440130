import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from "@mui/material/styles";
import { useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, } from ".";
export { useTheme } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(4),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(4),
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (_jsxs(DialogTitle, { sx: { m: 2, p: 2 }, ...other, children: [children, onClose ? (_jsx(IconButton, { onClick: onClose, sx: {
                    position: "absolute",
                    right: 8,
                    top: 8,
                }, children: _jsx(Close, { color: "primary" }) })) : null] }));
};
const FormDialog = ({ open = false, setOpen, title = "", actions, children, maxWidth = "lg", ...props }) => {
    const theme = useTheme();
    const mdMatches = useMediaQuery(theme.breakpoints.down("md"));
    return (_jsxs(BootstrapDialog, { fullWidth: true, onClose: () => setOpen(false), open: open, maxWidth: maxWidth, fullScreen: mdMatches, children: [_jsx(BootstrapDialogTitle, { onClose: () => setOpen(false), children: title }), _jsx(DialogContent, { dividers: true, children: children }), _jsx(DialogActions, { children: actions })] }));
};
export default FormDialog;
