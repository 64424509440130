import { jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "../../mui";
import { PlatformLanguage } from "../../../utils/enums";
import { EnglishContent, SpanishContent, FrenchContent, GermanContent } from "../../../utils/content";
export const getContent = (field) => {
    const language = localStorage.getItem("language") || PlatformLanguage.English;
    let content = "";
    switch (language) {
        case PlatformLanguage.English:
            content = EnglishContent?.[field] ? EnglishContent?.[field] : "";
            break;
        case PlatformLanguage.Spanish:
            content = SpanishContent?.[field] ? SpanishContent?.[field] : "";
            break;
        case PlatformLanguage.French:
            content = FrenchContent?.[field] ? FrenchContent?.[field] : "";
            break;
        case PlatformLanguage.German:
            content = GermanContent?.[field] ? GermanContent?.[field] : "";
            break;
        default:
            content = EnglishContent?.[field] ? EnglishContent?.[field] : "";
            break;
    }
    return content;
};
const ContentTypography = ({ field = "", children, ...props }) => {
    if (!field || field === "")
        return "";
    return (_jsxs(Typography, { ...props, children: [getContent(field), children] }));
};
export default ContentTypography;
