import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, TablePagination, useTheme, useMediaQuery } from "../../mui";
import { ContentCard } from "../content";
import { FormFieldOrder } from "../../../utils/enums";
import constants from "../../../utils/constants";
const FormCards = ({ rows = [], handleRowAction = () => null, refetch = () => null, order = FormFieldOrder.asc, orderBy = constants.DEFAULT_SORT, count = 0, page = 0, rowsPerPage = 0, rowsPerPageOptions = constants.ROWS_PER_PAGE.map((pages) => parseInt(pages)), pagination = true, }) => {
    const theme = useTheme();
    const mdMatches = useMediaQuery(theme.breakpoints.down("md"));
    const smMatches = useMediaQuery(theme.breakpoints.down("sm"));
    return (_jsxs(Grid, { container: true, spacing: 4, children: [rows?.map((row, index) => (_jsx(Grid, { item: true, xs: (smMatches && 11) || (mdMatches && 6) || 3, children: _jsx(ContentCard, { row: row, handleRowAction: handleRowAction }) }, index))), _jsx(Grid, { item: true, xs: 12, children: pagination ? (_jsx(TablePagination, { component: "div", rowsPerPageOptions: rowsPerPageOptions || constants.ROWS_PER_PAGE.map((pages) => parseInt(pages)), count: count || 0, rowsPerPage: rowsPerPage || parseInt(constants.PAGE_LIMIT), page: page || 0, onPageChange: (event, newPage) => {
                        refetch({
                            filters: { newPage, rowsPerPage, order, orderBy },
                        });
                    }, onRowsPerPageChange: (event) => {
                        refetch({
                            filters: { newPage: 0, rowsPerPage: parseInt(event.target.value, 10), order, orderBy },
                        });
                    } })) : null })] }));
};
export default FormCards;
