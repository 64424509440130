const constants = {
    APP_LANGUAGES: {
        English: "English",
    },
    PAGE_LIMIT: "4",
    ROWS_PER_PAGE: ["4", "8", "16", "32", "48"],
    DEFAULT_FREQUENCY: "5",
    DEFAULT_ORDER: "asc",
    DEFAULT_SORT: "createdAt",
    DEFAULT_KANBAN_FIELD: "status",
    DEFAULT_PRIMARY_THEME: "#00a893",
    GQL_FIELD_TYPE: {
        LIST: "LIST",
        OBJECT: "OBJECT",
        Enum: "Enum",
        String: "String",
        Float: "Float",
        Boolean: "Boolean",
    },
    STATIC_STORAGE_BUCKET_FOLDERS: {
        avatars: "avatars",
        exports: "exports",
        imports: "imports",
        logos: "logos",
        pools: "pools",
    },
    OPERATION_TYPE: {
        ADD: "Add",
        EDIT: "Edit",
    },
    CONTENTS_CUSTOM_TYPES: {
        accountOwners: "accountOwners",
        assignees: "assignees",
    },
    MODEL_CATEGORY_MAP: {
        leads: "relations",
        campaigns: "relations",
        tasks: "content",
        clients: "content",
        inventories: "content",
        deals: "relations",
        invoices: "content",
        contacts: "relations",
        engagements: "relations",
        products: "financials",
        subscriptions: "financials",
        charges: "financials",
        content: ["clients", "inventories", "invoices", "tasks"],
        relations: ["deals", "contacts", "engagements", "leads", "campaigns"],
        financials: ["products", "subscriptions", "charges"],
    },
    SETTING_TABS: {
        Personal: "Personal",
        Notifications: "Notifications",
        Organization: "Organization",
        Features: "Features",
        Team: "Team",
    },
    PARENT_ORGANIZATION_TYPE: {
        parent: "parent",
        sub: "sub",
    },
    POPULAR_EMAILS: [
        "gmail",
        "yahoo",
        "aol",
        "outlook",
        "aim",
        "yandex",
        "protonmail",
        "zoho",
        "gmx",
        "tutanota",
        "tutamail",
        "tuta",
        "keemail",
        "icloud",
    ],
    STATES: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
    ],
    COUNTRIES: ["USA"],
};
export default constants;
