import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { CircularProgress, Paper, Tooltip, Grid, IconButton, Button, ErrorHandling, useMediaQuery, useTheme, } from "../../mui";
import { Add } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { ContentTypography, getContent } from "../content";
import { UserPermission, FeatureType } from "../../../utils/enums";
import ManagePoolStatsDialog from "../dialogs/ManagePoolStatsDialog";
import FormStatistic from "./FormStatistic";
import constants from "../../../utils/constants";
import defaults from "../../../utils/defaults";
const STATISTICS = gql `
  query StatisticsDialog($organizationModelId: ID!) {
    statistics(organizationModelId: $organizationModelId) {
      id
    }
  }
`;
const useStyles = makeStyles((theme) => ({
    gridList: {
        padding: 8,
        height: "auto",
        width: "auto",
        overflow: "auto",
    },
    paper: {
        padding: 16,
        minWidth: 128,
        height: 128,
        cursor: "pointer",
        width: "max-content",
    },
    paperItemGroup: {
        margin: 8,
    },
}));
const FormStatistics = ({ organizationModelId = null, modelName = "", permissions = [], setSnackBar = () => null }) => {
    const classes = useStyles();
    const theme = useTheme();
    const mdMatches = useMediaQuery(theme.breakpoints.down("md"));
    const [openManageStats, setManageStats] = React.useState({
        open: false,
        stat: null,
        action: null,
    });
    const { loading, error, data } = useQuery(STATISTICS, {
        variables: {
            organizationModelId,
        },
    });
    if (loading)
        return (_jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, sx: { margin: "16%" }, children: _jsx(CircularProgress, { size: 40, color: "primary" }) }) }));
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const manageActionPermitted = permissions?.find((permission) => permission?.feature === FeatureType.StatisticsManage)?.permission ===
        UserPermission.Manage;
    return (_jsxs(_Fragment, { children: [openManageStats.open && (_jsx(ManagePoolStatsDialog, { organizationModelId: organizationModelId, openManageStats: openManageStats, setManageStats: setManageStats, modelName: modelName, setSnackBar: setSnackBar })), _jsxs(Grid, { container: true, spacing: 1, className: classes.gridList, wrap: "nowrap", children: [data?.statistics?.map((stat, index) => (_jsx(Grid, { item: true, children: _jsx(FormStatistic, { id: stat.id, openManageStats: openManageStats, setManageStats: setManageStats, permissions: permissions }) }, index))), manageActionPermitted ? (_jsx(Grid, { item: true, children: _jsx(Paper, { elevation: 6, className: classes.paper, children: mdMatches ? (_jsx(IconButton, { sx: { m: 3 }, onClick: () => setManageStats({
                                    open: true,
                                    stat: defaults.statistics,
                                    action: constants.OPERATION_TYPE.ADD,
                                }), children: _jsx(Add, { fontSize: "large", color: "primary" }) })) : (_jsx(Tooltip, { title: getContent("addStatMessage"), children: _jsxs(Button, { sx: { my: 4 }, color: "primary", variant: "text", onClick: () => setManageStats({
                                        open: true,
                                        stat: defaults.statistics,
                                        action: constants.OPERATION_TYPE.ADD,
                                    }), children: [_jsx(ContentTypography, { variant: "button", color: "secondary", fontWeight: "bold", sx: { textTransform: "none" }, field: "addStatLabel" }), _jsx(Add, { fontSize: "medium", color: "primary" })] }) })) }) })) : null] })] }));
};
export default FormStatistics;
