import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { makeStyles, Grid, FormTable, IconButton, Loader, ErrorHandling, Button, useTheme, useMediaQuery, } from "../../mui";
import { Add, PersonAdd } from "@mui/icons-material";
import { UserRole } from "../../../utils/enums";
import constants from "../../../utils/constants";
import ManageUser from "../personal/ManageUser";
import { ContentTypography } from "../../common/content";
const TEAM = gql `
  query Team {
    currentOrganization {
      id
      team {
        id
        firstName
        lastName
        email
        role
        status
        jobTitle
        permissions {
          id
          permission
          feature
        }
      }
    }
    currentUser {
      id
      role
    }
  }
`;
const useStyles = makeStyles((theme) => ({
    addItem: { marginLeft: "auto !important" },
}));
let headers = [
    {
        label: "First Name",
        key: "firstName",
        component: null,
        scope: null,
        align: "center",
        numeric: false,
        width: "16%",
    },
    {
        label: "Last Name",
        key: "lastName",
        component: null,
        scope: null,
        align: "center",
        numeric: false,
        width: "16%",
    },
    {
        label: "Role",
        key: "role",
        component: null,
        scope: null,
        align: "center",
        numeric: false,
        width: "16%",
    },
    {
        label: "Status",
        key: "status",
        component: null,
        scope: null,
        align: "center",
        numeric: false,
        width: "16%",
    },
    {
        label: "Email",
        key: "email",
        component: null,
        scope: null,
        align: "center",
        numeric: false,
        width: "48%",
    },
];
const Team = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const mdMatches = useMediaQuery(theme.breakpoints.down("md"));
    const organizationId = localStorage.getItem("organizationId") || null;
    const [actionDialogState, setActionDialogState] = React.useState({
        open: false,
        id: null,
        action: null,
    });
    const { data, loading, error } = useQuery(TEAM);
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const manageActionPermitted = [UserRole.Admin, UserRole.Staff].includes(data?.currentUser?.role);
    return (_jsxs(_Fragment, { children: [_jsx(ManageUser, { organizationId: organizationId, data: data?.currentOrganization?.team?.find((user) => user?.id === actionDialogState?.id), actionDialogState: actionDialogState, setActionDialogState: setActionDialogState }), manageActionPermitted && (_jsx(Grid, { container: true, spacing: 2, children: _jsx(Grid, { item: true, className: classes.addItem, children: mdMatches ? (_jsx(IconButton, { size: "medium", color: "primary", onClick: () => setActionDialogState({
                            open: true,
                            id: null,
                            action: constants.OPERATION_TYPE.ADD,
                        }), children: _jsx(PersonAdd, { fontSize: "inherit" }) })) : (_jsxs(Button, { sx: { m: 1 }, color: "primary", variant: "outlined", onClick: () => setActionDialogState({
                            open: true,
                            id: null,
                            action: constants.OPERATION_TYPE.ADD,
                        }), children: [_jsx(ContentTypography, { variant: "button", color: "secondary", sx: { textTransform: "none" }, field: "newTeamMember" }), _jsx(Add, { fontSize: "small" })] })) }) })), _jsx(Grid, { container: true, spacing: 2, children: _jsx(Grid, { item: true, xs: 12, container: true, spacing: 1, children: _jsx(Grid, { item: true, xs: 12, children: data?.currentOrganization?.team?.length === 0 ? (_jsx(ContentTypography, { color: "secondar", m: 8, align: "center", field: "noDataDescription" })) : (_jsx(FormTable, { headers: headers, rows: data?.currentOrganization?.team, fields: [], notIncludedFields: ["firstName", "lastName", "email", "role", "status"], handleSelectAllVisible: false, handleRowVisible: manageActionPermitted, handleRowAction: setActionDialogState, navigateRowVisible: false, pagination: false, onSubmitCheck: () => null })) }) }) })] }));
};
export default Team;
