import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Divider, Grid, Loader, ErrorHandling, makeStyles, useMediaQuery, useTheme } from "../components/mui";
import { ContentTypography } from "../components/common/content";
import AppLayout from "./common/AppLayout";
import { FormBar } from "../components/common/forms";
import Report from "../components/Reports/Report";
import ManageReport from "../components/Reports/ManageReport";
import { FeatureType, UserPermission } from "../utils/enums";
import ColorModeContext from "../routes/ColorModeContext";
import RouteConstants from "../routes/RouteConstants";
import constants from "../utils/constants";
const REPORTS = gql `
  query OrganizationReports($filters: queryFilters!) {
    reports(filters: $filters) {
      count
      page
      rowsPerPage
      order
      orderBy
      search
      list {
        id
      }
    }
    currentUser {
      id
      role
      organization {
        id
        primaryTheme
      }
      platformSettings {
        mode
      }
      permissions {
        id
        feature
        permission
      }
    }
  }
`;
const useStyles = makeStyles((theme) => ({
    section: {
        padding: 16,
        [theme.breakpoints.down("md")]: {
            padding: 12,
        },
        [theme.breakpoints.down("sm")]: {
            padding: 8,
        },
    },
}));
const ReportsContainer = (props) => {
    const organizationId = localStorage.getItem("organizationId") || null;
    const classes = useStyles();
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.down("sm"));
    const colorMode = React.useContext(ColorModeContext);
    const [actionDialogState, setActionDialogState] = React.useState({
        open: false,
        id: null,
        action: null,
    });
    React.useEffect(() => {
        const userId = localStorage.getItem("userId");
        const organizationId = localStorage.getItem("organizationId");
        if (!userId || !organizationId) {
            return history.push(RouteConstants.Login.path);
        }
    }, []);
    const { loading, error, data, refetch } = useQuery(REPORTS, {
        variables: {
            organizationId,
            filters: {},
        },
    });
    React.useEffect(() => {
        if (data) {
            colorMode.defaultMode({
                mode: data?.currentUser?.platformSettings?.mode,
            });
            colorMode.togglePrimaryTheme({
                color: data?.currentUser?.organization?.primaryTheme || constants.DEFAULT_PRIMARY_THEME,
            });
        }
    }, [data]);
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const manageActionPermitted = data?.currentUser?.permissions?.find((permission) => permission?.feature === FeatureType.ReportsManage)
        ?.permission === UserPermission.Manage;
    const downloadActionPermitted = data?.currentUser?.permissions?.find((permission) => permission?.feature === FeatureType.DownloadContent)
        ?.permission === UserPermission.Manage;
    return (_jsxs(AppLayout, { children: [_jsx(ManageReport, { organizationId: organizationId, actionDialogState: actionDialogState, setActionDialogState: setActionDialogState }), _jsxs(Grid, { container: true, spacing: 2, alignItems: "flex-start", justifyContent: "center", className: classes.section, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { align: "right", variant: "h5", color: "primary", m: 1, fontWeight: "bold", field: "reportsLabel" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Divider, { flexItem: true }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormBar, { showDelete: null, setActionDialogState: manageActionPermitted ? setActionDialogState : null, openFiltersDialog: false, setFiltersDialog: null, openSchemaDialog: false, setSchemaDialog: null, deleteFunctionality: false, view: null, setView: null, search: data?.reports?.search || "", refetch: refetch }) }), _jsx(Grid, { item: true, xs: 12, children: _jsxs(Grid, { container: true, spacing: 2, children: [data?.reports?.list?.length === 0 ? (_jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { color: "secondar", m: 4, align: "center", field: "noDataDescription" }) })) : null, data?.reports?.list?.map((report, index) => (_jsx(Grid, { item: true, xs: smMatches ? 12 : 6, children: _jsx(Report, { id: report?.id || null, manageActionPermitted: manageActionPermitted, downloadActionPermitted: downloadActionPermitted, onActionClick: setActionDialogState }) }, index)))] }) })] })] }));
};
export default ReportsContainer;
