import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { makeStyles, FormSnackBar, Grid, Button, Paper, MenuList, MenuItem, ListItemText, ListItemIcon, Divider, CircularProgress, ErrorHandling, } from "../mui";
import { Check } from "@mui/icons-material";
import { ContentTypography, getContent } from "../common/content";
import Address from "../common/sections/Address";
import Assets from "../common/sections/Assets";
import Comments from "../common/sections/comments/Comments";
import defaults from "../../utils/defaults";
const POOL_SECTIONS = gql `
  query PoolSections($poolId: ID!) {
    pool(id: $poolId) {
      id
      comments {
        id
        createdBy {
          id
          firstName
          lastName
        }
        label
      }
      assets {
        id
        url
      }
      address {
        id
        streetNumber
        streetName
        city
        state
        country
        postalCode
        longitude
        latitude
      }
    }
  }
`;
const UPLOAD_ASSET = gql `
  mutation UploadAsset($poolId: ID!, $data: poolInput!) {
    uploadAsset(poolId: $poolId, data: $data) {
      id
    }
  }
`;
const REMOVE_ASSET = gql `
  mutation RemoveAsset($id: ID!) {
    removeAsset(id: $id) {
      success
    }
  }
`;
export const MANAGE_ADDRESS = gql `
  mutation ManageAddress($data: addressInput!) {
    manageAddress(data: $data) {
      id
    }
  }
`;
export const ADD_COMMENT = gql `
  mutation AddComment($data: commentInput!) {
    addComment(data: $data) {
      id
    }
  }
`;
export const EDIT_COMMENT = gql `
  mutation EditComment($id: ID!, $data: commentInput!) {
    editComment(id: $id, data: $data) {
      id
    }
  }
`;
export const REMOVE_COMMENT = gql `
  mutation RemoveComment($id: ID!) {
    removeComment(id: $id) {
      success
    }
  }
`;
const useStyles = makeStyles((theme) => ({
    row: {
        marginTop: "40px !important",
        marginLeft: "auto !important",
        height: 180,
    },
}));
const PoolSections = ({ poolId = null, disabled = false }) => {
    const classes = useStyles();
    const [showOptions, setShowOptions] = React.useState(false);
    const [showAddress, setShowAddress] = React.useState(false);
    const [showAssets, setShowAssets] = React.useState(false);
    const [showComments, setShowComments] = React.useState(false);
    const [snackBar, setSnackBar] = React.useState({
        open: false,
        message: "",
        type: "",
    });
    const [getPoolSections, { loading, error, data }] = useLazyQuery(POOL_SECTIONS);
    const [uploadAsset, { loading: uploadAssetLoading }] = useMutation(UPLOAD_ASSET, {
        refetchQueries: ["PoolSections"],
    });
    const [removeAsset, { loading: removeAssetLoading }] = useMutation(REMOVE_ASSET, {
        refetchQueries: ["PoolSections"],
    });
    const [manageAddress] = useMutation(MANAGE_ADDRESS, {
        refetchQueries: ["PoolSections"],
    });
    const [addComment] = useMutation(ADD_COMMENT, {
        refetchQueries: ["PoolSections"],
    });
    const [editComment] = useMutation(EDIT_COMMENT, {
        refetchQueries: ["PoolSections"],
    });
    const [removeComment] = useMutation(REMOVE_COMMENT, {
        refetchQueries: ["PoolSections"],
    });
    React.useEffect(() => {
        if (poolId) {
            getPoolSections({ variables: { poolId } });
        }
    }, [poolId]);
    if (loading || uploadAssetLoading)
        return (_jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, sx: { margin: "10%" }, children: _jsx(CircularProgress, { size: 40, color: "primary" }) }) }));
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const uploadFunction = (event) => uploadAsset({
        variables: {
            poolId,
            data: { uploadAsset: event.target.files[0] },
        },
        onCompleted: () => setSnackBar({
            open: true,
            message: `Asset uploaded successfully!`,
            type: "success",
        }),
        onError: () => setSnackBar({
            open: true,
            message: `${getContent("errorMessage")}`,
            type: "error",
        }),
    });
    const onRemoveAsset = (id) => removeAsset({
        variables: {
            id,
        },
        onCompleted: () => setSnackBar({
            open: true,
            message: `Asset ${getContent("removedSuccessMessage")}`,
            type: "success",
        }),
        onError: () => setSnackBar({
            open: true,
            message: `${getContent("errorMessage")}`,
            type: "error",
        }),
    });
    const managePoolAddress = (address) => manageAddress({
        variables: {
            data: {
                id: address?.id,
                streetNumber: address?.streetNumber,
                streetName: address?.streetName,
                city: address?.city,
                country: address?.country,
                postalCode: address?.postalCode,
                state: address?.state,
                poolId,
            },
        },
        onCompleted: () => setSnackBar({
            open: true,
            message: `Updated address successfully!`,
            type: "success",
        }),
        onError: () => setSnackBar({
            open: true,
            message: `${getContent("errorMessage")}`,
            type: "error",
        }),
    });
    const addPoolComment = (label) => addComment({
        variables: {
            data: {
                label,
                poolId,
            },
        },
        onCompleted: () => setSnackBar({
            open: true,
            message: `Added comment successfully!`,
            type: "success",
        }),
        onError: () => setSnackBar({
            open: true,
            message: `${getContent("errorMessage")}`,
            type: "error",
        }),
    });
    const editPoolComment = (comment) => {
        let data = {
            label: comment?.label || "",
            poolId,
        };
        let id = comment?.id;
        editComment({
            variables: {
                id,
                data,
            },
            onCompleted: () => setSnackBar({
                open: true,
                message: `Updated comment successfully!`,
                type: "success",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
    };
    ``;
    const removePoolComment = (id) => removeComment({
        variables: {
            id,
        },
        onCompleted: () => setSnackBar({
            open: true,
            message: `Removed comment successfully!`,
            type: "success",
        }),
        onError: () => setSnackBar({
            open: true,
            message: `${getContent("errorMessage")}`,
            type: "error",
        }),
    });
    return (_jsxs(_Fragment, { children: [_jsx(FormSnackBar, { snackBar: snackBar, setSnackBar: setSnackBar }), _jsxs(Grid, { container: true, spacing: 4, alignItems: "center", justifyContent: "center", children: [_jsxs(Grid, { item: true, className: classes.row, children: [_jsx(Button, { disabled: disabled, sx: { width: 240 }, variant: "outlined", color: "primary", fullWidth: true, onClick: () => setShowOptions(!showOptions), children: _jsx(ContentTypography, { field: "addSectionLabel", color: "inherit" }) }), showOptions && (_jsx(Paper, { sx: { width: 240 }, children: _jsxs(MenuList, { dense: true, children: [_jsxs(MenuItem, { onClick: () => {
                                                setShowAddress(true);
                                                setShowOptions(!showOptions);
                                            }, children: [_jsx(ListItemText, { inset: true, children: getContent("addressLabel") }), data?.pool?.address || showAddress ? (_jsx(ListItemIcon, { children: _jsx(Check, {}) })) : null] }), _jsx(Divider, { flexItem: true }), _jsxs(MenuItem, { onClick: () => {
                                                setShowAssets(true);
                                                setShowOptions(!showOptions);
                                            }, children: [_jsx(ListItemText, { inset: true, children: getContent("assetsLabel") }), data?.pool?.assets?.length > 0 || showAssets ? (_jsx(ListItemIcon, { children: _jsx(Check, {}) })) : null] }), _jsx(Divider, { flexItem: true }), _jsxs(MenuItem, { onClick: () => {
                                                setShowComments(true);
                                                setShowOptions(!showOptions);
                                            }, children: [_jsx(ListItemText, { inset: true, children: getContent("commentsLabel") }), data?.pool?.comments?.length > 0 || showComments ? (_jsx(ListItemIcon, { children: _jsx(Check, {}) })) : null] })] }) }))] }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Divider, { flexItem: true }) }), data?.pool?.address || showAddress ? (_jsx(Grid, { item: true, xs: 12, children: _jsx(Address, { onSave: disabled ? null : managePoolAddress, data: data?.pool?.address || defaults.address, refetchQueries: ["PoolSections"] }) })) : null, data?.pool?.assets?.length > 0 || showAssets ? (_jsx(Grid, { item: true, xs: 12, children: _jsx(Assets, { onSave: uploadFunction, onDelete: onRemoveAsset, data: data?.pool?.assets || [] }) })) : null, data?.pool?.comments?.length > 0 || showComments ? (_jsx(Grid, { item: true, xs: 12, children: _jsx(Comments, { onAdd: disabled ? null : addPoolComment, onEdit: editPoolComment, onDelete: removePoolComment, data: data?.pool?.comments || [] }) })) : null] })] }));
};
export default PoolSections;
