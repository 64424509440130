import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton, useTheme, useMediaQuery, } from "@mui/material";
import { DeleteTwoTone, DeleteOutline } from "@mui/icons-material";
import { ContentTypography } from "../common/content";
const DeleteHandling = ({ onDelete = () => null, mobileOnly = false, variant = "outlined", style = {} }) => {
    const theme = useTheme();
    const lgMatches = useMediaQuery(theme.breakpoints.down("lg")) || mobileOnly;
    const [open, setOpen] = React.useState(false);
    return (_jsxs(React.Fragment, { children: [onDelete &&
                (lgMatches ? (_jsx(IconButton, { color: "error", onClick: () => setOpen(!open), size: "medium", style: style, children: _jsx(DeleteTwoTone, { fontSize: "medium" }) })) : (_jsxs(Button, { size: "small", color: "error", variant: variant, onClick: () => setOpen(!open), children: [_jsx(ContentTypography, { variant: "button", color: "error", fontWeight: "bold", sx: { textTransform: "none" }, field: "deleteLabel" }), _jsx(DeleteOutline, { fontSize: "small" })] }))), _jsxs(Dialog, { open: open, onClose: () => setOpen(!open), children: [_jsx(DialogTitle, { color: "error", children: _jsx(ContentTypography, { fontWeight: "bold", field: "areYouSureLabel" }) }), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: _jsx(ContentTypography, { field: "deleteHandlingMessage" }) }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onDelete, children: _jsx(ContentTypography, { variant: "button", color: "primary", fontWeight: "bold", sx: { textTransform: "none" }, field: "yesLabel" }) }), _jsx(Button, { onClick: () => setOpen(!open), autoFocus: true, children: _jsx(ContentTypography, { variant: "button", color: "primary", fontWeight: "bold", sx: { textTransform: "none" }, field: "cancelLabel" }) })] })] })] }));
};
export default DeleteHandling;
