import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { makeStyles, Grid, Typography, Tabs, Tab, TabPanel, Loader, ErrorHandling } from "../mui";
import constants from "../../utils/constants";
import Personal from "./personal/Personal";
import Notifications from "./personal/Notifications";
import OrganizationTab from "./organization/OrganizationTab";
import FeaturesTab from "./features/FeaturesTab";
import Team from "./organization/Team";
import ColorModeContext from "../../routes/ColorModeContext";
import { AppMode } from "../../utils/enums";
const USER = gql `
  query Personal($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      phoneNumber
      dateOfBirth
      organization {
        id
        primaryTheme
      }
      platformSettings {
        mode
        language
        currency
      }
      avatar {
        id
        url
      }
      notificationSettings {
        dailyReports
        dealsClosing
        deletionAlerts
        device
      }
    }
  }
`;
const useStyles = makeStyles((theme) => ({
    section: {
        padding: 16,
        [theme.breakpoints.down("md")]: {
            padding: 12,
        },
        [theme.breakpoints.down("sm")]: {
            padding: 8,
        },
    },
    subText: {
        color: "#D3D3D3",
    },
    card: {
        overflow: "hidden",
        overflowX: "hidden",
        padding: 20,
        borderRadius: 9,
    },
    untransformBttn: {
        textTransform: "none",
    },
}));
const Profile = (props) => {
    const id = localStorage.getItem("userId") || null;
    const classes = useStyles();
    const history = useHistory();
    const colorMode = React.useContext(ColorModeContext);
    const [tabValue, setTabValue] = React.useState(constants.SETTING_TABS.Personal);
    const { loading, error, data } = useQuery(USER, {
        variables: {
            id,
        },
    });
    React.useEffect(() => {
        if (data) {
            colorMode.defaultMode({
                mode: data?.user?.platformSettings?.mode || AppMode.light,
            });
            colorMode.togglePrimaryTheme({
                color: data?.user?.organization?.primaryTheme || constants.DEFAULT_PRIMARY_THEME,
            });
        }
    }, [data]);
    React.useEffect(() => {
        const search = history.location.search;
        const params = new URLSearchParams(search);
        const viewParam = params.get("view");
        if (viewParam) {
            setTabValue(viewParam);
        }
    }, [history]);
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(ErrorHandling, { error: error });
    return (_jsx(Grid, { container: true, spacing: 2, alignItems: "flex-start", justifyContent: "center", className: classes.section, children: _jsxs(Grid, { item: true, style: { width: "100%" }, children: [_jsx(Tabs, { value: tabValue, onChange: (event, newValue) => {
                        history.replace({
                            search: `?view=${newValue}`,
                        });
                        setTabValue(newValue);
                    }, textColor: "primary", indicatorColor: "primary", variant: "scrollable", scrollButtons: false, children: Object.keys(constants.SETTING_TABS).map((tab, index) => (_jsx(Tab, { value: tab, label: _jsx(Grid, { container: true, direciton: "row", alignItems: "center", justifyContent: "center", children: _jsxs(Typography, { variant: "subtitle2", sx: { textTransform: "none" }, fontWeight: "bold", children: ["\u00A0", tab] }) }) }, index))) }), _jsx(TabPanel, { value: tabValue, index: constants.SETTING_TABS.Personal, children: _jsx(Personal, { data: data }) }), _jsx(TabPanel, { value: tabValue, index: constants.SETTING_TABS.Notifications, children: _jsx(Notifications, { data: data }) }), _jsx(TabPanel, { value: tabValue, index: constants.SETTING_TABS.Organization, children: _jsx(OrganizationTab, {}) }), _jsx(TabPanel, { value: tabValue, index: constants.SETTING_TABS.Features, children: _jsx(FeaturesTab, {}) }), _jsx(TabPanel, { value: tabValue, index: constants.SETTING_TABS.Team, children: _jsx(Team, {}) })] }) }));
};
export default Profile;
