import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { FormSnackBar, Loader, ErrorHandling } from "../../components/mui";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { ManagePoolContentDialog } from "../common/dialogs";
import { FormContent } from "../common/forms";
import { FormFieldOrder } from "../../utils/enums";
import constants from "../../utils/constants";
import defaults from "../../utils/defaults";
import { getContent } from "../common/content";
const POOL_FRAGMENT = gql `
  fragment PoolFragment on pool {
    id
    createdAt
    updatedAt
    name
    description
    status
  }
`;
const POOLS = gql `
  query PoolsView($organizationModelId: ID!, $filters: queryFilters!) {
    __type(name: "pool") {
      name
      fields {
        name
        type {
          name
          kind
          enumValues {
            name
          }
        }
      }
    }
    pools(organizationModelId: $organizationModelId, filters: $filters) {
      count
      page
      rowsPerPage
      order
      orderBy
      search
      view
      headers {
        label
        key
        component
        scope
        align
        numeric
        width
      }
      list {
        ...PoolFragment
      }
    }
  }
  ${POOL_FRAGMENT}
`;
const POOL = gql `
  query PoolQuery($id: ID!) {
    pool(id: $id) {
      ...PoolFragment
      readOnly {
        label
        value
      }
      contents {
        id
        key
        value
        schemaId
        poolId
      }
    }
  }
  ${POOL_FRAGMENT}
`;
export const ADD_POOL = gql `
  mutation AddPool($data: poolInput!) {
    addPool(data: $data) {
      ...PoolFragment
    }
  }
  ${POOL_FRAGMENT}
`;
export const EDIT_POOL = gql `
  mutation EditPool($id: ID!, $data: poolInput!) {
    editPool(id: $id, data: $data) {
      ...PoolFragment
    }
  }
  ${POOL_FRAGMENT}
`;
export const REMOVE_POOL = gql `
  mutation RemovePool($id: ID!) {
    removePool(id: $id) {
      success
    }
  }
`;
const EXPORT_POOLS = gql `
  mutation ExportInvoices($organizationModelId: ID!, $filters: queryFilters!) {
    exportInvoices(organizationModelId: $organizationModelId, filters: $filters) {
      success
    }
  }
`;
const IMPORT_POOLS = gql `
  mutation ImportPools($data: usageInput!) {
    importPools(data: $data) {
      success
    }
  }
`;
const Pools = ({ organizationId = null, organizationModelId = null, modelName = null, category = null, permissions = [], }) => {
    const notIncludedFields = [
        "id",
        "selected",
        "edited",
        "createdAt",
        "updatedAt",
        "contents",
        "readOnly",
        "__typename",
    ];
    const [actionDialogState, setActionDialogState] = React.useState({
        open: false,
        id: null,
        action: null,
    });
    const [snackBar, setSnackBar] = React.useState({
        open: false,
        message: "",
        type: "",
    });
    const { loading, error, data, refetch } = useQuery(POOLS, {
        variables: {
            organizationModelId,
            filters: {},
        },
    });
    const [getPool, { data: poolData }] = useLazyQuery(POOL);
    const [addPool] = useMutation(ADD_POOL, {
        refetchQueries: ["PoolsView"],
    });
    const [editPool] = useMutation(EDIT_POOL, {
        refetchQueries: ["PoolsView"],
    });
    const [removePool] = useMutation(REMOVE_POOL, {
        refetchQueries: ["PoolsView"],
    });
    const [exportPools] = useMutation(EXPORT_POOLS);
    const [importPools] = useMutation(IMPORT_POOLS, {
        refetchQueries: ["PoolsView"],
    });
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const onContentAdd = (pool) => {
        const contents = pool?.contents?.map((content) => ({
            id: content?.id,
            poolId: pool?.id,
            schemaId: content?.schemaId,
            key: content?.key,
            value: content?.value,
        })) || [];
        let data = { ...pool, organizationModelId, contents };
        delete data?.readOnly;
        addPool({
            variables: {
                data,
            },
            onCompleted: ({ addPool }) => setSnackBar({
                open: true,
                message: `${addPool?.name} ${getContent("createSuccessMessage")}`,
                type: "success",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onContentEdit = (pool) => {
        const contents = pool?.contents?.map((content) => ({
            id: content?.id,
            poolId: pool?.id,
            schemaId: content?.schemaId,
            key: content?.key,
            value: content?.value,
        })) || [];
        let data = { ...pool, organizationModelId, contents };
        delete data?.id;
        delete data?.createdAt;
        delete data?.updatedAt;
        delete data?.selected;
        delete data?.editable;
        delete data?.edited;
        delete data?.readOnly;
        delete data?.__typename;
        editPool({
            variables: {
                id: pool.id,
                data,
            },
            onCompleted: ({ editPool }) => setSnackBar({
                open: true,
                message: `${editPool?.name} ${getContent("updatedSuccessMessage")}`,
                type: "success",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onContentDelete = () => {
        removePool({
            variables: { id: poolData?.pool?.id },
            onCompleted: () => setSnackBar({
                open: true,
                message: `${pool?.name} ${getContent("removedSuccessMessage")}`,
                type: "info",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onDialogChange = ({ open = false, id = null, action = null }) => {
        if (action === constants.OPERATION_TYPE.EDIT && id) {
            getPool({
                variables: { id },
            });
        }
        setActionDialogState({
            open,
            id,
            action,
        });
    };
    const handleExport = () => {
        exportPools({
            variables: {
                organizationModelId,
                filters: {
                    order: data?.invoices?.order,
                    orderBy: data?.invoices?.orderBy,
                    search: data?.invoices?.search,
                },
            },
            onCompleted: () => setSnackBar({
                open: true,
                message: `${getContent("exportMutationMessage")}`,
                type: "info",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
    };
    const handleImport = ({ uploadAsset }) => {
        if (uploadAsset) {
            importPools({
                variables: {
                    data: { uploadAsset, organizationModelId },
                },
                onCompleted: () => setSnackBar({
                    open: true,
                    message: `${getContent("importMutationMessage")}`,
                    type: "info",
                }),
                onError: () => setSnackBar({
                    open: true,
                    message: `${getContent("errorMessage")}`,
                    type: "error",
                }),
            });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormSnackBar, { snackBar: snackBar, setSnackBar: setSnackBar }), _jsx(ManagePoolContentDialog, { displayPool: true, modelName: modelName, poolData: poolData?.pool || null, poolId: poolData?.pool?.id || null, defaultSchema: defaults.pools, organizationModelId: organizationModelId, open: actionDialogState?.open || false, onDialogChange: onDialogChange, onSave: actionDialogState.action === constants.OPERATION_TYPE.ADD ? onContentAdd : onContentEdit, notIncludedFields: notIncludedFields, fields: data?.__type?.fields, operationType: actionDialogState?.action || constants.OPERATION_TYPE.ADD, onDelete: actionDialogState.action === constants.OPERATION_TYPE.EDIT ? onContentDelete : null, permissions: permissions }), _jsx(FormContent, { pool: true, organizationModelId: organizationModelId || null, modelName: modelName, category: category, headers: data?.pools?.headers || [], data: data?.pools?.list || [], count: data?.pools?.count || 0, page: data?.pools?.page || 0, defaultView: data?.pools?.view || null, order: data?.pools?.order || FormFieldOrder.asc, orderBy: data?.pools?.orderBy || constants.DEFAULT_SORT, search: data?.pools?.search || "", defaultOrder: constants.DEFAULT_ORDER, rowsPerPage: data?.pools?.rowsPerPage || 0, editMutation: onContentEdit, refetch: refetch, defaultSchema: defaults.pools, notIncludedFields: notIncludedFields, fields: data?.__type?.fields, actionDialogState: actionDialogState?.open || false, setActionDialogState: onDialogChange, handleExport: handleExport, handleImport: handleImport, loading: loading, error: error, permissions: permissions })] }));
};
export default Pools;
