import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { Card, CardContent, Grid, Typography, FormField, IconButton, Button, DeleteHandling, useTheme, useMediaQuery, } from "../../mui";
import { Check, Clear, Edit } from "@mui/icons-material";
import { FormFieldType } from "../../../utils/enums";
import defaults from "../../../utils/defaults";
import { ContentMap, ContentTypography } from "../content";
import constants from "../../../utils/constants";
const REMOVE_ADDRESS = gql `
  mutation RemoveAddress($id: ID!) {
    removeAddress(id: $id) {
      success
    }
  }
`;
export const AddressFields = ({ address = null, handleChange = () => null }) => (_jsxs(Grid, { container: true, spacing: 4, alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "streetNumber", size: "small", fieldType: FormFieldType.TextField, value: address?.streetNumber, label: _jsx(ContentTypography, { field: "streetNumberLabel" }), onChange: handleChange }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "streetName", size: "small", fieldType: FormFieldType.TextField, value: address?.streetName, label: _jsx(ContentTypography, { field: "streetNameLabel" }), onChange: handleChange }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "city", size: "small", fieldType: FormFieldType.TextField, value: address?.city, label: _jsx(ContentTypography, { field: "cityLabel" }), onChange: handleChange }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "postalCode", size: "small", fieldType: FormFieldType.TextField, value: address?.postalCode, label: _jsx(ContentTypography, { field: "postalCodeLabel" }), onChange: handleChange }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "state", size: "small", fieldType: FormFieldType.SingleSelect, enumValues: constants.STATES, value: address?.state, label: _jsx(ContentTypography, { field: "stateLabel" }), onChange: handleChange }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "country", size: "small", fieldType: FormFieldType.SingleSelect, value: address?.country, enumValues: constants.COUNTRIES, label: _jsx(ContentTypography, { field: "countryLabel" }), onChange: handleChange }) })] }));
const Address = ({ onSave = () => null, data = null, refetchQueries = [] }) => {
    const theme = useTheme();
    const lgMatches = useMediaQuery(theme.breakpoints.down("lg"));
    const [address, setAddress] = React.useState(defaults.address);
    const [preview, setPreview] = React.useState(true);
    const [removeAddress] = useMutation(REMOVE_ADDRESS, {
        refetchQueries,
    });
    React.useEffect(() => {
        if (data) {
            setAddress(data);
        }
    }, [data]);
    const handleChange = (event) => {
        setAddress({
            ...address,
            [event.target.name]: event.target.value,
        });
    };
    return (_jsx(Card, { children: _jsxs(CardContent, { children: [_jsxs(Grid, { container: true, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { color: "primary", variant: "subtitle2", field: "addressLabel" }) }), _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { color: "secondary", children: `${address?.streetNumber || ""} ${address?.streetName || "–"}` }), _jsx(Typography, { color: "secondary", children: `${address?.city || ""}  ${address?.state || ""} ${address?.country || "–"} ${address?.postalCode || ""}` })] }), _jsx(Grid, { item: true, sx: { marginLeft: "auto !important" }, children: onSave &&
                                preview &&
                                (lgMatches ? (_jsx(IconButton, { onClick: () => setPreview(!preview), size: "medium", children: _jsx(Edit, { fontSize: "inherit" }) })) : (_jsxs(Button, { size: "small", color: "primary", variant: "outlined", onClick: () => setPreview(!preview), children: [_jsx(ContentTypography, { variant: "button", color: "secondary", sx: { textTransform: "none" }, field: "editLabel" }), _jsx(Edit, { fontSize: "small" })] }))) })] }), preview ? (_jsx(ContentMap, { lat: address?.latitude, lng: address?.longitude })) : (_jsx(AddressFields, { address: address, handleChange: handleChange })), preview ? null : (_jsxs(Grid, { container: true, spacing: 2, alignItems: "flex-end", justifyContent: "flex-end", sx: { my: 1 }, children: [_jsx(Grid, { item: true, children: lgMatches ? (_jsx(IconButton, { color: "primary", onClick: () => {
                                    onSave(address);
                                    setPreview(!preview);
                                }, size: "medium", children: _jsx(Check, { fontSize: "inherit" }) })) : (_jsxs(Button, { size: "small", color: "primary", variant: "outlined", onClick: () => {
                                    onSave(address);
                                    setPreview(!preview);
                                }, children: [_jsx(ContentTypography, { variant: "button", color: "secondary", sx: { textTransform: "none" }, field: "saveLabel" }), _jsx(Check, { fontSize: "small" })] })) }), _jsx(Grid, { item: true, children: lgMatches ? (_jsx(IconButton, { onClick: () => {
                                    setAddress(address || defaults.address);
                                    setPreview(!preview);
                                }, size: "medium", children: _jsx(Clear, { fontSize: "medium" }) })) : (_jsxs(Button, { size: "small", variant: "outlined", onClick: () => {
                                    setAddress(address || defaults.address);
                                    setPreview(!preview);
                                }, children: [_jsx(ContentTypography, { variant: "button", color: "secondary", sx: { textTransform: "none" }, field: "cancelLabel" }), _jsx(Clear, { fontSize: "small" })] })) }), address?.id && _jsx(Grid, { item: true, children: _jsx(DeleteHandling, { onDelete: () => removeAddress(address?.id) }) })] }))] }) }));
};
export default Address;
