import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Button } from "../../mui";
import { ContentTypography } from "../content";
import { useStripe, useElements, Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.STRIPE_KEY);
const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: "https://example.com/order/123/complete",
            },
        });
        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        }
        else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };
    return (_jsxs(Grid, { container: true, spacong: 4, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { color: "primary", variant: "subtitle2", field: "paymentLabel" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx("form", { onSubmit: handleSubmit, children: _jsxs(Grid, { container: true, spacing: 2, alignContent: "flex-end", justifyContent: "flex-end", children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(PaymentElement, {}) }), _jsx(Grid, { item: true, children: _jsx(Button, { disabled: !stripe, variant: "outlined", size: "small", children: _jsx(ContentTypography, { field: "submitLabel" }) }) })] }) }) })] }));
};
const FormPayment = ({ clientSecret = null }) => {
    const options = {
        clientSecret,
    };
    return (_jsx(Elements, { stripe: stripePromise, options: options, children: _jsx(CheckoutForm, {}) }));
};
export default FormPayment;
